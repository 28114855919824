//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { DashboardNotificationModel } from "../models/dashboardnotification.model";
import { DashboardRelatioshipManagerActivityModel } from "../models/dashboardrelationshipmanageractivity.model";
import { DashboardRelatioshipManagerProductSummaryModel } from "../models/dashboardrelationshipmanagerproductsummary.model";
import { DashboardRelatioshipManagerUserSummaryModel } from "../models/dashboardrelationshipmanagerusersummary.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class DashboardService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT RELATIONSHIP MANAGER

	selectProductForRelationshipManager(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerProductSummary: DashboardRelatioshipManagerProductSummaryModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerProductSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTPRODUCTFORRELATIONSHIPMANAGER);
	}

	selectRelationshipManagerForRelationshipManager(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerUserSummary: DashboardRelatioshipManagerUserSummaryModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerUserSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORRELATIONSHIPMANAGER);
	}

	selectActivityForRelationshipManager(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerActivity: DashboardRelatioshipManagerActivityModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerActivity), WebAddressConstant.STRING_URL_DASHBOARD_SELECTACTIVITYFORRELATIONSHIPMANAGER);
	}

	//#endregion


	//#region OPERATION AND SUPPORT

	selectProductForOperationAndSupport(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerProductSummary: DashboardRelatioshipManagerProductSummaryModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerProductSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTPRODUCTFOROPERATIONANDSUPPORT);
	}

	selectActivityForOperationAndSupport(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerActivity: DashboardRelatioshipManagerActivityModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerActivity), WebAddressConstant.STRING_URL_DASHBOARD_SELECTACTIVITYFOROPERATIONANDSUPPORT);
	}

	selectRelationshipManagerForOperationAndSupport(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerUserSummary: DashboardRelatioshipManagerUserSummaryModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerUserSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFOROPERATIONANDSUPPORT);
	}

	//#endregion


	//#region BRANCH HEAD

	selectActivityForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerActivity: DashboardRelatioshipManagerActivityModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerActivity), WebAddressConstant.STRING_URL_DASHBOARD_SELECTACTIVITYFORBRANCHHEAD);
	}

	selectProductForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerProductSummary: DashboardRelatioshipManagerProductSummaryModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerProductSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTPRODUCTFORBRANCHHEAD);
	}

	selectRelationshipManagerForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerUserSummary: DashboardRelatioshipManagerUserSummaryModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerUserSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORBRANCHHEAD);
	}

	//#endregion


	//#region ACCOUNTING AND FINANCE

	selectProductForAccountingAndFinance(interfaceGeneralService: GeneralServiceInterface, modelDashboardRelatioshipManagerProductSummary: DashboardRelatioshipManagerProductSummaryModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardRelatioshipManagerProductSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTPRODUCTFORACCOUNTINGANDFINANCE);
	}

	//#endregion


	//#region NOTIFICATION DASHBOARD

	selectNotificationForCustomer(interfaceGeneralService: GeneralServiceInterface, modelDashboardNotificationModel: DashboardNotificationModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardNotificationModel), WebAddressConstant.STRING_URL_DASHBOARD_SELECTNOTIFICATIONFORCUSTOMER);
	}

	//#endregion
}

//#endregion
