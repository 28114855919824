//#region IMPORT

import { ENUM_BRINSUPDATE_PUBLISHEDSTATUS, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class LandingHomepageModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for landing homepage model (brins website cms).
		Author: Dimas Dandy.
		Created on : Monday, 27 October 2024. 			Updated on : .
		Created by : Dimas Dandy.						Updated by : .
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;

	Title?: string;
	OrderList?: number;
	PublishedStatus?: ENUM_BRINSUPDATE_PUBLISHEDSTATUS;
	PublishedStatusName?: string;

	listModelLandingHomepageGallery?: Array<ProductBaseGalleryModel>;

	/* Attribute - END */


	//#region VALIDATION

	validateInsert(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Landing Homepage");

	 	if (this.Title == null || this.Title === undefined || this.Title === "")
		{
			modelResponse.MessageContent = "Title tidak boleh kosong!";
		}
		else if (this.OrderList == null || this.OrderList === undefined)
		{
			modelResponse.MessageContent = "Urutan tidak boleh kosong!";
		}
		else if (this.listModelLandingHomepageGallery === undefined)
		{
			modelResponse.MessageContent = "Attachment tidak boleh kosong!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setPublishedStatus(): void
	{
		if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.ApprovedBySigner)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_APPROVEDBYSIGNER;
		}
		else if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.RejectedBySigner)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_REJECTEDBYSIGNER;
		}
		else if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.SubmittedByMaker)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_SUBMITTEDBYMAKER;
		}
		else
		{
			this.PublishedStatusName = StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion
}

//#endregion