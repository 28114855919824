//#region IMPORT

import { ENUM_BRINSUPDATE_PUBLISHEDSTATUS, ENUM_HEADER_TYPE, ENUM_PROJECT_CODE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ProductBaseGalleryModel } from "./bases/productbasegallery.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class HeaderModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for header model (brins website cms).
		Author: Dimas Dandy.
		Created on : Monday, 21 October 2022. 			Updated on : .
		Created by : Dimas Dandy.						Updated by : .
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;

	Name?: string;
	Description?: string;
	TitleFirst?: string;
	TitleSecond?: string;
	UrlPath?: string;
	HeaderType?: ENUM_HEADER_TYPE;
	ProjectCode?: ENUM_PROJECT_CODE;
	PublishedStatus?: ENUM_BRINSUPDATE_PUBLISHEDSTATUS;
	PublishedStatusName?: string;

	ParentToken?: string;
	ParentID?: string;
	HeaderDraftID?: string;

	listModelHeaderGallery?: Array<ProductBaseGalleryModel>;
	listSubHeader?: Array<HeaderModel>;

	/* Attribute - END */


	//#region VALIDATION

	validateInsert(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Header");

	 	if (this.TitleFirst == null || this.TitleFirst === undefined || this.TitleFirst === "")
		{
			modelResponse.MessageContent = "Title tidak boleh kosong!";
		}
		else if (this.TitleSecond == null || this.TitleSecond === undefined || this.TitleSecond === "")
		{
			modelResponse.MessageContent = "Title tidak boleh kosong!";
		}
		else if (this.Description == null || this.Description === undefined || this.Description === "")
		{
			modelResponse.MessageContent = "Deskripsi tidak boleh kosong!";
		}
		else if (this.listModelHeaderGallery === undefined)
		{
			modelResponse.MessageContent = "Attachment tidak boleh kosong!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setPublishedStatus(): void
	{
		if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.ApprovedBySigner)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_APPROVEDBYSIGNER;
		}
		else if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.RejectedBySigner)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_REJECTEDBYSIGNER;
		}
		else if (this.PublishedStatus === ENUM_BRINSUPDATE_PUBLISHEDSTATUS.SubmittedByMaker)
		{
			this.PublishedStatusName = StringConstant.STRING_APPROVAL_STATUS_SUBMITTEDBYMAKER;
		}
		else
		{
			this.PublishedStatusName = StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion
}

//#endregion