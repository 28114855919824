<div id="divMainTermsAndCondition">
    <div class="DivHeaderPolicy">
        <img id="imgHeaderShape" src="../assets/shapes/sakina/shape_faq_friend.svg" >
		<img id="imgHeaderResponsive" src="../assets/shapes/sakina/shape_faq_friend_mobile_responsive.svg" >
        <h1>Kebijakan Privasi</h1>
    </div>

    <div class="DivSiteContainer DivMainPolicy">
        <section>
            <h1>Kebijakan Privasi Umum untuk Layanan BRINS</h1>
            <p>PT BRI Asuransi Indonesia yang selanjutnya disebut BRINS sepenuhnya menyadari bahwa data pribadi merupakan aset terpenting bagi Pengguna, sehingga BRINSN sangat menghargai kerahasiaan dan perlindungan terhadap data pribadi Pengguna terkait dengan penggunaan layanan produk Asuransi BRINS.</p>
            <p>Keamanan, kenyamanan, serta privasi Pengguna merupakan prioritas bagi BRINS. BRINS menerapkan kebijakan dan praktik terbaik yang bertujuan untuk menjaga dan memelihara privasi serta keamanan data pribadi Pengguna, sesuai peraturan Perlindungan Data Pribadi, untuk pemrosesan data terkait dengan penggunaan layanan produk Asuransi BRINS ("Kebijakan Privasi").</p>
            <p>BRINS selalu meningkatkan layanan kami kepada Pengguna dan senantiasa melakukan pembaruan layanan demi untuk melindungi privasi dan keamanan data pribadi Pengguna sebagai nasabah kami.</p>
        </section>

        <section>
            <h3>I.Tujuan dan Ruang Lingkup Kebijakan Privasi</h3>
            <p>Kebijakan Privasi ini mengatur mengenai kebijakan-kebijakan dan praktik-praktik yang diterapkan oleh BRINS dalam memperoleh, memperbaiki, memperbarui, menyebarluaskan, menampilkan, mengumumkan, mengalihkan, mengungkapkan, menghapuskan dan memusnahkan (untuk selanjutnya disebut "Pemrosesan") data pribadi Pengguna sehubung dengan penggunaan layanan produk Pengguna dengan penggunaan layanan produk Asuransi BRINS, meliputi:</p>
            <ol class="OrderedNumber" type="a">
                <li>
                    <p>Jasa penyediaan keuangan nonbank dari BRINS.</p>
                </li>
                <li>
                    <p>
                        Situs web, fitur, aplikasi, media sosial, atau bentuk media komunikasi lainnya yang disediakan atau digunakan oleh BRINS.
                    </p>
                </li>
                <p>(seluruhnya kemudian disebut "Layanan").</p>
                <p>Selanjutnya, Kebijakan Privasi ini berlaku untuk semua pemilik data Pribadi (Subyek Data), yang terkait dengan penggunaan layanan produk dan/atau jasa perbankan BRINS.</p>
                <p>(seluruhnya kemudian disebut "Pengguna").</p>
            </ol>
        </section>

        <section>
            <h3>II.Tujuan Pemrosesan Data Pribadi</h3>
            <p>Dalam melakukan Pemrosesan data pribadi Pengguna, BRINS memiliki tujuan sebagai berikut:</p>
            <ol class="OrderedNumber" type="a">
                <li>
                    <p>
                        Untuk menjalankan prinsip Know Your Customer (KYC) dan memberikan personalisasi kepada Pengguna terhadap penggunaan Layanan yang disediakan oleh BRINS;
                    </p>
                </li>
                <li>
                    <p>
                        Untuk menjalankan mandat peraturan perundang-undangan;
                    </p>
                </li>
                <li>
                    <p>
                        Untuk menjalankan penyelesaian masalah terkait akses terhadap Layanan (troubleshoot);
                    </p>
                </li>
                <li>
                    <p>
                        Untuk kebutuhan pemasaran dari layanan dan produk yang ditawarkanoleh BRINS, hal ini termasuk seperti mengirimkan promosi, produk baru, berita, survei, pembaruan terkait layanan;
                    </p>
                </li>
                <li>
                    <p>
                        Untuk kebutuhan pemrosesan Data Pribadi guna menghasilkan data profil Pengguna;
                    </p>
                </li>
                <li>
                    <p>
                        Untuk kepentingan loyality programme kepada Pengguna seperti gift, undian dan sebagaimananya; dan
                    </p>
                </li>
                <li>
                    <p>
                        Untuk menawarkan iklan/promosi maupun penawaran dari pihak lain yang bekerja sama dengan BRINS.
                    </p>
                </li>
            </ol>
        </section>

        <section>
            <h3>
                III. Data Pribadi yang Diperoleh dan Dikumpulkan oleh BRINS
            </h3>
            <p>BRINS memperoleh dan mengumpulkan informasi yang mengidentifikasi dan/atau dapat diidentifikasi secara tersendiri atau dikombinasi dengan informasi lainnya baik secara langsung maupun  tidak langsung melalui sistem elektronik dan/atau non-elektronik yang terkait dengan informasi tersebut yang dalam hal ini terbatas pada Layanan ("Data Pribadi").</p>
            <p>Data Pribadi Pengguna yang dikumpulkan oleh BRINS dapat diberikan oleh Pengguna secara langsung maupun dari pihak ketiga (seperti misalnya ketika Pengguna mendaftar atau menggunakan Layanan/Ketika menghubungi layanan yang dimiliki oleh BRINS seperti Contact Center BRINS atau melalui Agen BRINS). BRINS akan mengumpulkan Data Pribadi Pengguna dalam berbagai bentuk dengan tujuan, termasuk yang diperkenankan oleh peraturan perundang-undangan yang berlaku di Republik Indonesia.</p>

            <ol class="OrderedNumber" type="A">
                <li>
                    <p>Data Pribadi Pengguna yang dikumpulkan dalam penyelenggaraan Layanan ini adalah:</p>
                    <ol class="OrderedNumber" type="a">
                        <li>
                            <p>Data Umum</p>
                            <ol class="OrderedNumber">
                                <li>
                                    <p>
                                        Nama lengkap;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Alamat tempat tinggal;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Tempat dan Tanggal lahir;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Jenis kelamin;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Kewarganegaraan;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Nama Gadis Ibu Kandung;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Agama;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Pekerjaan;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Pendidikan;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Nomor Induk Kependudukan / Paspor;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Nomor Handphone;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Alamat email;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Nomor telepon kantor/rumah; dan/atau
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Data Pribadi yang dikombinasikan untuk mengidentifikasi sesorang.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Data Khusus:</p>
                            <ol class="OrderedNumber">
                                <li>
                                    <p>Data biometrik yang memungkinkan identifikasi unik terhadap individu namun tidak terbatas pada sidik jari;</p>
                                </li>
                                <li>
                                    <p>Data keunagan pribadi namun tidak terbatas seperti Penghasilan dan Nomor Wajib Pajak; dan/atau;</p>
                                </li>
                                <li>
                                    <p>Data lainnya sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        Selain itu, sepanjang informasi lain, termasuk profil pribadi dan/atau tanda pengenal, yang terasosiasi atau tergabung dalam Data Pribadi, maka informasi tersebut juga merupakan Data Pribadi. Pengguna memiliki pilihan untuk memberikan Data Pribadi tambahan kepada BRINS untuk kebutuhan personalisasi akun di luar yang telah disebutkan di atas.
                    </p>
                </li>
                <li>
                    <p>
                        Data penelusuran atas informasi yang dikumpulkan ketika Pengguna menggunakan Layanan yang diberikan oleh BRINS. Informasi yang berkenaan dengan Data Pribadi Pengguna termasuk namun tidak terbatas pada:
                    </p>
                    <ol class="OrderedNumber">
                        <li>
                            <p>Data pencarian, riwayat penelusuran (termasuk tanggal dan waktu yang terekam);</p>
                        </li>
                        <li>
                            <p>Keterkaitan atau preferensi Pengguna yang dikumpulkan dari penggunaan Layanan;</p>
                        </li>
                        <li>
                            <p>Foto, suara, kontak, daftar penggilan ataupun interaksi lainnya dalam Layanan yang diizinkan oleh Pengguna untuk diakses melalui perangkat yang Pengguna miliki. BRINS tidak pernah memindai atau mengambil foto-foto pada album dan/atau kamera dalam perangkat Pengguna tanpa seizin dari Pengguna.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>Data Pribadi yang dibuat dan didapatkan dari hasil penggunaan layanan milik BRINS, termasuk namun tidak terbatas pada:</p>
                    <ol class="OrderedNumber">
                        <li>
                            <p>Data olahan teknis, seperti mobile positioning, penilaian lokasi dan profiling lanjutan;</p>
                        </li>
                        <li>
                            <p>Informasi tentang Data yang diperoleh dari aktifitas pemakaian layanan perbankan yang Pengguna akses, seperti pembayaran tagihan maupun pemrofilan dan segmentasi Pengguna;</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        Data Pribadi dari Grup Usaha BRINS dan/atau pihak ketiga lainnya yang berpartisipasi bermitra dengan BRINS atau mengadakan kerja sama dengan BRINS.
                    </p>
                </li>
            </ol>
        </section>

        <section>
            <h3>IV. Penampilan, Pengumuman, Pengalihan, Penyebarluasan dan Pengungkapan Data Pribadi</h3>
            <p>BRINS tidak akan menjual, menukar, menampilkan, mengumumkan, mengalihkan, menyebarluaskan dan/atau mengungkapkan Data Pribadi dan informasi apapun yang berkaitan dengan nasabah, pengunjung, atau penggunaa Layanan BRINS.</p>
            <p>Pengguna bertanggung jawab untuk menjaga kerahasiaan detail Data Pribadi Pengguna dan harus selalu menjaga dan bertanggung jawab atas keamanan perangkat yang Pengguna gunkan untuk mengakses Layanan BRINS.</p>
            <p>Nasabah dengan ini mengakui dan jika diwajibkan oleh peraturan Perlindungan Data Pribadi yang berlaku, secara tegas menyetujui bahwa Data Pribadi yang diperoleh dan dikumpulkan oleh BRINS bersama dengan informasi terkait transaksi yang relevan, dapat diungkapkan kepada lembaga pemerintah yang berwenang, ataupun pihak lain yang berwenang berdasarkan undang-undang atau perjanjian kerja sama, dalam hal:</p>
            <ol class="OrderedNumber" type="A">
                <li>
                    <p>Untuk kepentingan hukum yang berlaku dan/atau untuk menanggapi proses hukum yang sedang berjalan;</p>
                </li>
                <li>
                    <p>Untuk melindungi keselamatan BRINS, keselamatan Pengguna atau keselamatan orang lain atau demi kepentingan yang sah dalam konteks:</p>
                    <ol class="OrderedNumber" type="a">
                        <li>
                            <p>
                                Keamanan nasional;
                            </p>
                        </li>
                        <li>
                            <p>
                                Proses penegakan hukum;
                            </p>
                        </li>
                        <li>
                            <p>
                                Penyelenggara negara;
                            </p>
                        </li>
                        <li>
                            <p>
                                Kepentingan pengawasan sektor jasa keuangan nonbank;
                            </p>
                        </li>
                        <li>
                            <p>
                                Keadaan darurat yang telah ditetapkan oleh Pemerintah;
                            </p>
                        </li>
                        <li>
                            <p>
                                Untuk kepentingan audit internal dan/atau digital forensic atas tindak pidana atau pelanggaran peraturan atau kebijakan di lingkungan BRINS;
                            </p>
                        </li>
                        <li>
                            <p>
                                Jika diperlukan sehubungan dengan proses hukum yang diajukan terhadap BRINS, pejabat, karyawan, atau vendor terkait;
                            </p>
                        </li>
                        <li>
                            <p>
                                Untuk menetapkan, melaksanakan, melindungi, mempertahankan dan menegakkan hak-hak hukum BRINS;
                            </p>
                        </li>
                        <li>
                            <p>
                                Dalam rangka Pemrosesan Data Pribadi dengan BRINS, BRINS hanya akan memberikannya dengan itikad baik dalam bentuk ringkasan atas dasar kepentingan yang sah di mata hukum. Dalam hal BRINS melakukan Pemrosesan Data Pribadi yang membuka profil pribadi Pengguna, BRINS akan melakukan upaya terbaik untuk melindungi privasi atas Data Pribadi Pengguna, yang dilaksanakan setelah dilakukannya penPenggunatanganan perjanjian kerahasiaan, atau persetujuan tambahan dengan cara memberikan pemberitahuan kepada Pengguna sebelum Data Pribadi Pengguna diungkapkan kepada afiliasi BRINS serta tunduk pada Kebijakan Privasi Pengguna diungkapkan kepada afiliasi BRINS serta tunduk pada Kebijakan Privasi dan peraturan perundang-undangan yang berlaku, sehingga tetap dapat memastikan kerahasiaan Data Pribadi Pengguna; atau;
                            </p>
                        </li>
                    </ol>
                </li>
                <p>Untuk melakukan pengungkapan Data Pribadi Pengguna kepada pihak ketiga, dalam rangka untuk memastikan BRINS dapat memberikan Layanan kepada Pengguna dan membantu Pemerintah Negara Indonesia beserta institusi pendukungnya untuk pelaksanaan fungsi-fungsi Negara.</p>
                <p>Pengungkapan ini dilakukan dengan mempertimbangkan aspek-aspek pengamanannya sehingga memastikan dalam proses pengungkapan tidak ada data-data yang disalahgunakan. </p>
            </ol>
        </section>

        <section>
            <h3>V. Penyimpanan Data Pribadi</h3>
            <p>
                BRINS berkomitmen untuk menyimpan Data Pribadi Pengguna dengan perlindungan terbaik selama diperlukan untuk menyediakan Layanan ini. Sebagaian Data Pribadi Pengguna dapat pula dikelola, diproses dan disimpan oleh pihak ketiga yang bekerja sama dengan BRINS baik di wilayah Indonesia maupun di luar wilayah Republik Indonesia guna menjaga kinerja Layanan dengan tetap mematuhi kewajiban atas akses dan efektifitas pengawasan sesuai hukum yang berlaku.
            </p>
            <p>
                BRINS akan menyimpan Data Pribadi sebgaimana disebutkan di atas sepanjang hal tersebut dibutuhkan untuk mencapai tujuan mengapa Data Pribadi tersebut dikumpulkan, ketika Pengguna masih menggunakan Layanan, atau sepanjang penyimpanan tersebut diharuskan atau diperkenankan sesuai dengan kebijakan retensi data milik BRINS serta peraturan perundang-undangan yang berlaku di Republik Indonesia.
            </p>
            <p>
                BRINS dapat mentransfer Data Pribadi keluar Wilayah republik Indonesia selama hal tersebut diperlukan dalam rangka pelaksanaan Layanan Asuransi kepada Pengguna (misalnya Pembelian Produk Asuransi).
            </p>
            <p>
                BRINS akan berhenti untuk menyimpan Data Pribadi Pengguna, atau menghilangkan cara-cara yang dengan mana Data Pribadi Pengguna dapat diasosiasikan dengan Pengguna, segera setelah beralasan untuk berasumsi bahwa tujuan pengumpulan Data Pribadi tersebut tidak lagi terpenuhi dengan penyimpanan Data Pribadi terkait dan penyimpanan tidak lagi diperlukan untuk tujuan hukum dan bisnis.
            </p>
        </section>

        <section>
            <h3>VI. Akses ke Data Pribadi Pengguna</h3>
            <p>BRINS berkomitmen untuk memenuhi hak Pengguna untuk mengakses Data Pribadi Pengguna yang dikumpulkan dan diproses oleh BRINS. Dalam memenuhi permintaan akses ke data pribada Pengguna, BRINS dapat menolak permintaan Pengguna apabila BRINS menemukan bahwa permintaan ke Data Pribadi Pengguna memenuhi salah satu atau beberapa kondisi, berikut ini:</p>
            <ol class="OrderedNumber" type="A">
                <li>
                    <p>Membahayakan keamanan atau kesehatan fisik atau kesehatan mental Pemilik Data Pribadi dan/atau orang lain;</p>
                </li>
                <li>
                    <p>Berdampak pada pengungkapan data Pribadi milik orang lain; dan/atau;</p>
                </li>
                <li>
                    <p>Bertentangan dengan kepentingan pertahanan dan keamanan nasional.</p>
                </li>
            </ol>
            <p>Untuk informasi lebih lanjut terkait dengan permintaan akses data pribadi Pengguna, Pengguna dapat menghubungi saluran komunikasi yang tersedia berdasarkan Kebijakan Privasi ini.</p>

        </section>

        <section>
            <h3>VII. Perbaikan dan Pembaruan Data Pribadi Pengguna</h3>
            <p>Dalam hal Pengguna menemukan kekeliruan yang ditampilkan mengenai Data pribadi Pengguna dikarenakan ketidakakuratan atau diperlukan dilakukan pembaruan atas Data Pribadi Pengguna, maka Pengguna dapat meminta kepada BRINS untuk memperbaiki, melengkapi dan/atau memperbarui Data Pribadi Pengguna yang berada dalam pengelolaan BRINS, dengan menghubungi saluran komunikasi yang tersedia berdasarkan Kebijakan Privasi ini. Dalam hal terdapat ketidakakuratan dalam pemberian data pribadi dari Subyek Data, BRINS berhak menghentikan layanan dan/atau transaksi berdasarkan pengetahuan dan pertimbangan Bank.</p>
            <p>Kami menghimbau Pengguna untuk turut berperan aktif memastikan keakuratan dan pembaruan Data Pribadi Pengguna dari waktu ke waktu.</p>
        </section>

        <section>
            <h3>VIII. Penghapusan dan Pemusnahan Data Pribadi</h3>
            <p>Sesuai dengan peraturan perundang-undangan yang berlaku atau kebijakan retensi data milik BRINS atau atas permintaan/permohonan Pengguna, BRINS dapat menghapus dan/atau memusnahkan Data Pribadi Pengguna dari sistem (right to erase) agar data tersebut tidak lagi mengidentifikasi Pengguna, kecuali dalam hal:</p>
            <ol class="OrderedNumber" type="A">
                <li>
                    <p>Apabila perlu menyimpan Data Pribadi untuk memenuhi kewajiban hukum, keperluan pembuktian di kemudian hari, pajak, audit dan akuntasi, BRINS akan menyampaikan Data Pribadi yang diperlukan selama Pengguna menggunakan layanan milik BRINS atau sesuai jangka waktu yang disyaratkan oleh peraturan perundang-undangan yang berlaku; dan/atau</p>
                </li>
                <li>
                    <p>Data Pribadi dalam Data Pribadi masih berapa dalam periode retensi berdasarkan peraturan perundang-undangan yang berlaku.</p>
                </li>
            </ol>
            <p>Perihal keperluan pemusnahan Data pribadi Pengguna dari sistem (right to erase), diperlukan permohonan tertulis yang diajukan oleh Pengguna kepada Kantor Pusat BRINS agar kami dapat memusnahkan Data Pribadi dalam sistem milik BRINS.</p>

        </section>

        <section>
            <h3>IX. Keamanan Data Pribadi</h3>
            <p>
                Kerahasiaan Data Pribadi Pengguna adalah hal yang terpenting bagi BRINS. BRINS berkomitmen untuk memberlakukan upaya terbaiknya untuk melindungi dan mengamankan Data Pribadi Pengguna dari akses pengumpulan, pengolahan, penganalisasisan, penyimpanan, pengungkapan, perbaikan dan penghapusan oleh pihak-pihak yang tidak berwenang.
            </p>
            <p>
                Dalam hal terjadi akses dan kegiatan ilegal atas kerahasiaan Informasi Pribadi Pengguna yang berada di luar kendali BRINS, BRINS akan segara memberitahukan kepada Pengguna pada kesempatan pertama sehingga Pengguna dapat mengurangi risiko yang timbul atas hal tersebut.
            </p>
            <p>
                Pengguna bertanggung jawab untuk menjaga kerahasiaan detail Data Pribadi Pengguna, termasuk informasi mengenai username, password, email maupun OTP kepada siapapun dan untuk selalu menjaga dan bertanggung jawab atas keamanan perangkat yang Pengguna gunakan.
            </p>
        </section>

        <section>
            <h3>X. Pengakuan dan Persetujuan</h3>
            <p>Kebijakan Privasi ini dapat diubah dan/atau diperbarui dari waktu ke waktu. BRINS menyarankan kepada Pengguna agar selalu membaca secara seksama dan memeriksa halaman ini dari waktu ke waktu untuk mengetahui perubahan apapun yang ditimbulkan olehnya.</p>
            <p>Pengguna dapat menarik persetujuan Pengguna terhadap setiap atau seluruh pengumpulan, penggunaan, penggungkapan Data Pribadi Pengguna pada setiap waktu dengan memberikan pemberitahuan tertulis yang beralasan ke kontak yang tertera di bawah ini.</p>
            <p>Bergantung pada keadaan dan sifat dari persetujuan yang Pengguna tarik, Pengguna harus memahami dan mengakui bahwa BRINS berwenang untuk memenuhi atau tidak memenuhi permintaan penarikan persetujuan tersebut, maka Pengguna tidak lagi dapat menggunakan Layanan. Penarikan persetujuan oleh Pengguna dapat berakibat pada pernghentian akun Pengguna atau hubungan kontraktual Pengguna dengan BRINS yang terkait, di mana semua hak dan kewajiban yang dimiliki masing-maisng pihak masih sepenuhnya dilindungi.</p>
        </section>

        <section>
            <h3>XI. Pengaturan Layanan</h3>
            <p>Pengguna dapat memilih untuk tidak menerima layanan pemasaran, pengiklanan, atau aktivitas lainnya yang terkait dengan pengolahan Data Pribadi di atas dengan menghubungi BRINS melalui detail kontak yang tersedia di bawah pemberitahuan ini atau melalui mekanisme lain yang disiapkan oleh masing-masing Layanan kami. Mohon diperhatinkan, jika Pengguna memilih untuk tidak menerima satu Layanan, BRINS masih berhak untuk mengirimi Pengguna pesan terkait layanan BRINS, untuk Layanan lainnya yang Pengguna gunakan.</p>
        </section>

        <section>
            <h3>XII. Bahasa</h3>
            <p>Kebijakan Privasi ini disusun dan diterbitkan dalam Bahasa Inggris dan Bahasa Indonesia. Pengguna dapat menemukan bahasa yang berbeda untuk kebijakan privasi ini ketika Pengguna mengganti pengaturan bahasa di situs web atau aplikasi BRINS. Jika terjadi ketidaksesuaian antara kedua bahasa ini, versi bahasa Indonesia yang berlaku.</p>
        </section>

        <section>
            <h3>XIII. Hubungi Kami</h3>
            <p>
                Jika Pengguna memiliki pertanyaan tentang Kebijakan Privasi ini, silakan hubungi layanan pelanggan kami melalui informasi kontak berikut:
            </p>
            <ol class="OrderedNumber" type="a">
                <li>
                    <p>Contact Center BRINS: 14081</p>
                </li>
                <li>
                    <p>Email: callbrins@brins.co.id</p>
                </li>
                <li>
                    <p>Whatsapp: +62 81180 14081</p>
                </li>
                <li>
                    <p>Facebook: BRI Insurance</p>
                </li>
            </ol>
        </section>

        <section>
            <h3>XIV. Pengaman</h3>
            <p>
                Kebijakan dan praktek yang dilakukan PT BRI Asuransi Indonesia untuk menunjukan komitmen BRINS di dalam menjaga dan memelihara keamanan Pengguna pada saat Pengguna mengunjungi situs dan <a (click)="goToLink('www.brins.co.id')">www.brins.co.id</a>.
            </p>
        </section>

        <section>
            <h3>XV.Situs BRINS</h3>
            <p>
                Dalam hal Pengguna mengakses situs melalui URL link dari situs lain, pastikan kebenaran alamat yang Pengguna akses yaitu <a (click)="goToLink('www.brins.co.id')">https://​www.brins.co.id</a>
            </p>
            <p>
                BRINS dapat mengubah kebijakan privasi dan informasi pengamanan ini setiap saat untuk tetap menyesuaikan dengan situasi dan teknologi terbaru. Pengguna selalu dapat meninjau informasi dan kebijakan privasi BRINS yang terbaru di <a (click)="goToLink('www.brins.co.id')">https://​www.brins.co.id</a>.
            </p>
        </section>
    </div>
</div>

<app-footer></app-footer>