//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { BranchModel } from "../models/branch.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class BranchService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectBranch(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCH);
	}

	selectBranchByLocation(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHBYLOCATION);
	}

	selectBranchForCustomer(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFORCUSTOMER);
	}

	selectProvince(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTPROVINCE);
	}

	selectBranchByProvince(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHBYPROVINCE);
	}

	selectBranchByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHBYATTRIBUTES);
	}

	selectBranchByLocationForSurvey(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHBYLOCATIONFORSURVEY);
	}

	selectBranchForSurvey(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFORSURVEY);
	}

	selectBranchByLocationForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelBranch: BranchModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranch), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHBYLOCATIONFORWEBSITECUSTOMER);
	}

	selectBranchForBRINSEditorialWorkingUnit(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCH_SELECTBRANCHFORBRINSEDITORIALWORKINGUNIT);
	}

	//#endregion
}

//#endregion