//#region IMPORT

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_HTTP_STATUS, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { CryptographyFunction } from "src/app/functions/cryptography.function";
import { RegexFunction } from "src/app/functions/regex.function";
import { HandshakeModel } from "src/app/models/handshake.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { CustomerService } from "src/app/services/customer.service";
import { SessionService } from "src/app/services/session.service";
import { BasePublicComponent } from "../bases/basepublic.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-signin",
		templateUrl: "./signin.component.html",
		styleUrls: ["./signin.component.sass"]
	}
)

//#endregion



//#region CLASS

export class SigninComponent extends BasePublicComponent implements OnInit
{
	//#region DECLARATION

	public _modelUserPublic: UserModel;
	public _modelUserPublicRequest: UserModel;
	public _modelResponse: ResponseModel;

	public _booleanFormSignInEmail: boolean | null;
	public _booleanFormSignInVerifyEmail: boolean | null;
	public _booleanFormSignInWhatsApp: boolean | null;
	public _booleanFormSignInVerifyWhatsApp: boolean | null;
	public _booleanFormSignInSMS: boolean | null;
	public _booleanFormSignInVerifySMS: boolean | null;

	public _booleanButtonEmailInterval: boolean | null;
	public _booleanButtonVerifyEmailInterval: boolean | null;
	public _booleanButtonVerifyWhatsAppInterval: boolean | null;
	public _booleanButtonVerifySMSInterval: boolean | null;

	public _enumStringConstant = StringConstant;
	public _enumHttpStatus = ENUM_HTTP_STATUS;

	public _functionRegex: RegexFunction;

	public _stringDownloadCategory: string = "";
	public _stringWhatsApp: string = "WhatsApp";
	public _stringSMS: string = "SMS";

	public _arrayStringURL: Array<string> = [];
	public _arrayNumberEmailToken: Array<number | null> = [null, null, null, null, null , null];
	public _arrayNumberWhatsAppToken: Array<number | null> = [null, null, null, null, null , null];
	public _arrayNumberSMSToken: Array<number | null> = [null, null, null, null, null , null];

	public textTimer: any;
	public timerInterval: any;

	private _functionCryptography: CryptographyFunction;

	private _arrayElementInputEmailToken: NodeListOf<HTMLElement> | null = null;
	private _arrayElementInputEmailTokenWhatsApp: NodeListOf<HTMLElement> | null = null;
	private _arrayElementInputTokenSMS: NodeListOf<HTMLElement> | null = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceCustomer: CustomerService, serviceSession: SessionService, router: Router, private _routeActivated: ActivatedRoute)
	{
		super(serviceSession, router);

		this._modelUserPublic = new UserModel();
		this._modelUserPublicRequest = new UserModel();
		this._modelResponse = new ResponseModel();

		this._functionCryptography = new CryptographyFunction();
		this._functionRegex = new RegexFunction();

		this._booleanFormSignInEmail = null;
		this._booleanFormSignInVerifyEmail = null;
		this._booleanFormSignInWhatsApp = null;
		this._booleanFormSignInVerifyWhatsApp = null;
		this._booleanFormSignInSMS = null;
		this._booleanFormSignInVerifySMS = null;

		this._booleanButtonEmailInterval = false;
		this._booleanButtonVerifyEmailInterval = false;
		this._booleanButtonVerifyWhatsAppInterval = false;
		this._booleanButtonVerifySMSInterval = false;
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this._arrayElementInputEmailToken = document.querySelectorAll(".InputToken");
		this._arrayElementInputEmailTokenWhatsApp = document.querySelectorAll(".InputTokenWhatsapp");
		this._arrayElementInputTokenSMS = document.querySelectorAll(".InputTokenSMS");

		this._booleanFormSignInEmail = false;
		this._booleanFormSignInVerifyEmail = true;
		this._booleanFormSignInWhatsApp = true;
		this._booleanFormSignInVerifyWhatsApp = true;
		this._booleanFormSignInSMS = true;
		this._booleanFormSignInVerifySMS = true;
	}

	//#endregion


	//#region WEB SERVICE

	callSelectCustomerByEmail(booleanResend: boolean): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.Email = this._modelUserPublic.Email;
		// modelUser.Token = this.getTokenFromURLParameter();
		const modelResponseValidation: ResponseModel = modelUser.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: SigninComponent = this;

			this._serviceCustomer.selectCustomerByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS )
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifyEmail = booleanResend;
							componentCurrent._booleanFormSignInEmail = true;
						});
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifyEmail = booleanResend;
						});
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callRegisterCustomerByEmail(!booleanResend); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	callRegisterCustomerByEmail(booleanResend: boolean): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.Email = this._modelUserPublic.Email;
		modelUser.Culture = StringConstant.STRING_CULTURE_INDONESIAN;

		const modelResponseValidation: ResponseModel = modelUser.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: SigninComponent = this;

			this._serviceCustomer.registerCustomerByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifyEmail = booleanResend;
							componentCurrent._booleanFormSignInEmail = true;

							//set interval 1 minutes for resend Email OTP
							componentCurrent._booleanButtonVerifyEmailInterval = true;
							componentCurrent.startInterval();
						});
					}
					else if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_CASE_OTP_ONEMINUTE)
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifyEmail = true;
							componentCurrent._booleanFormSignInEmail = booleanResend;

							//set interval 1 minutes for send Email OTP
							componentCurrent._booleanButtonEmailInterval = true;
							componentCurrent.startInterval();
						});
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifyEmail = true;
							componentCurrent._booleanFormSignInEmail = booleanResend;
						});
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === "000049")
					{
						//user login failed (user belum terdaftar)
						componentCurrent.callRegisterCustomerByEmail(booleanResend);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callRegisterCustomerByEmail(booleanResend); });
					}
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	private callVerifyCustomerByEmail(): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.EmailToken = this.getStringEmailTokenFromForm();
		modelUser.Email = this._modelUserPublic.Email;

		let modelResponseValidation: ResponseModel = modelUser.validateEmailToken();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelUser.validateEmailToken();
		}
		else
		{

		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: SigninComponent = this;
			modelUser.EmailToken = this._functionCryptography.hashWithSHA256((this.getStringEmailTokenFromForm()));

			this._serviceCustomer.verifyCustomerByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.Data !== undefined)
					{
						const modelUserResponse: UserModel = new UserModel();
						modelUserResponse.setModelFromString(modelResponse.Data);
						modelUserResponse.modelDivision = undefined;

						if (modelUserResponse != null)
						{
							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);
							if (modelUserResponse.modelHandshake !== undefined)
							{
								const modelHandshake: HandshakeModel = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
							}
							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;
							componentCurrent._serviceSession.setUserSession(modelUserSession);

							componentCurrent._router.navigate(["/home"]);

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
						}
					}
					else
					{
						if (modelResponse.ServiceResponseCode === "030107")
						{
							componentCurrent._booleanFormSignInEmail = true;
							componentCurrent._booleanFormSignInVerifyEmail = true;
							componentCurrent._booleanFormSignInWhatsApp = false;
							componentCurrent._booleanFormSignInVerifyWhatsApp = true;
							componentCurrent._booleanFormSignInSMS = true;
							componentCurrent._booleanFormSignInVerifySMS = true;

							//clear interval timer
							clearInterval(componentCurrent.timerInterval);
							componentCurrent.textTimer = undefined;
						}
						else if (modelResponse.ServiceResponseCode === "090135" || modelResponse.ServiceResponseCode === "090110")
						{
							//wait 5 minutes
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});

						}
						else if (modelResponse.ServiceResponseCode === "090122")
						{
							componentCurrent._router.navigate(["/home"]);
						}
						else
						{

						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callVerifyCustomerByEmail(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	callUpdateCustomerMobilePhone(booleanResend: boolean): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const componentCurrent: SigninComponent = this;
		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.Email = this._modelUserPublic.Email;
		modelUser.MobilePhone = this._modelUserPublic.MobilePhone;
		modelUser.setMobilePhonePrefixIndonesian();

		this._serviceCustomer.setUserModel(modelUser);

		let modelResponseValidation: ResponseModel = modelUser.validateMobilePhone();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelUser.validateMobilePhone();
			this._serviceCustomer.updateCustomerMobilePhone
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS )
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifyWhatsApp = booleanResend;
							componentCurrent._booleanFormSignInWhatsApp = true;

							//clear interval time
							clearInterval(componentCurrent.timerInterval);
							componentCurrent.textTimer = undefined;
							componentCurrent._booleanButtonEmailInterval = false;
							componentCurrent._booleanButtonVerifyEmailInterval = false;
							componentCurrent._booleanButtonVerifySMSInterval = false;

							//set interval 1 minutes for resend WhatsApp OTP
							componentCurrent._booleanButtonVerifyWhatsAppInterval = true;
							componentCurrent.startInterval();
						});
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifyWhatsApp = true;
							componentCurrent._booleanFormSignInWhatsApp = booleanResend;
						});
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callUpdateCustomerMobilePhone(booleanResend); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	callUpdateCustomerMobilePhoneBySMS(booleanResend: boolean): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const componentCurrent: SigninComponent = this;
		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.Email = this._modelUserPublic.Email;
		modelUser.MobilePhone = this._modelUserPublic.MobilePhone;
		modelUser.setMobilePhonePrefixIndonesian();

		this._serviceCustomer.setUserModel(modelUser);

		let modelResponseValidation: ResponseModel = modelUser.validateMobilePhone();
		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelUser.validateMobilePhone();
			this._serviceCustomer.updateCustomerMobilePhoneBySMS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS )
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifySMS = booleanResend;
							componentCurrent._booleanFormSignInSMS = true;

							//clear interval time
							clearInterval(componentCurrent.timerInterval);
							componentCurrent.textTimer = undefined;
							componentCurrent._booleanButtonEmailInterval = false;
							componentCurrent._booleanButtonVerifyEmailInterval = false;
							componentCurrent._booleanButtonVerifyWhatsAppInterval = false;

							//set interval 1 minutes for resend SMS OTP
							componentCurrent._booleanButtonVerifySMSInterval = true;
							componentCurrent.startInterval();
						});
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormSignInVerifySMS = true;
							componentCurrent._booleanFormSignInSMS = booleanResend;
						});
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callUpdateCustomerMobilePhone(booleanResend); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	private callVerifyCustomerByMobilePhone(stringVerifyType: string): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;

		if(stringVerifyType === this._stringWhatsApp)
		{
			modelUser.MobilePhoneToken = this.getStringWhatsAppTokenFromForm();
		}
		if(stringVerifyType === this._stringSMS)
		{
			modelUser.MobilePhoneToken = this.getStringSMSTokenFromForm();
		}

		modelUser.MobilePhone = this._modelUserPublic.MobilePhone?.toString();
		modelUser.setMobilePhonePrefixIndonesian();

		let modelResponseValidation: ResponseModel = modelUser.validateMobilePhoneToken();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelUser.validateMobilePhoneToken();
		}
		else
		{

		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: SigninComponent = this;

			if(stringVerifyType === this._stringWhatsApp)
			{
				modelUser.MobilePhoneToken = this._functionCryptography.hashWithSHA256((this.getStringWhatsAppTokenFromForm()));
			}
			if(stringVerifyType === this._stringSMS)
			{
				modelUser.MobilePhoneToken = this._functionCryptography.hashWithSHA256((this.getStringSMSTokenFromForm()));
			}

			this._serviceCustomer.verifyCustomerByMobilePhone
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.Data !== undefined)
					{
						const modelUserResponse: UserModel = new UserModel();
						modelUserResponse.setModelFromString(modelResponse.Data);
						modelUserResponse.modelDivision = undefined;

						if (modelUserResponse != null)
						{
							componentCurrent._router.navigate(["/signup"]);
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if(stringVerifyType === componentCurrent._stringWhatsApp)
							{
								componentCurrent._booleanFormSignInEmail = true;
								componentCurrent._booleanFormSignInVerifyEmail = true;
								componentCurrent._booleanFormSignInWhatsApp = true;
								componentCurrent._booleanFormSignInVerifyWhatsApp = false;
								componentCurrent._booleanFormSignInSMS = true;
								componentCurrent._booleanFormSignInVerifySMS = true;
							}
							if(stringVerifyType === componentCurrent._stringSMS)
							{
								componentCurrent._booleanFormSignInEmail = true;
								componentCurrent._booleanFormSignInVerifyEmail = true;
								componentCurrent._booleanFormSignInWhatsApp = true;
								componentCurrent._booleanFormSignInVerifyWhatsApp = true;
								componentCurrent._booleanFormSignInSMS = true;
								componentCurrent._booleanFormSignInVerifySMS = false;
							}
						});
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callVerifyCustomerByMobilePhone(stringVerifyType); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}

	}

	//#endregion


	//region GETTER

	private getStringEmailTokenFromForm(): string
	{
		let stringEmailToken: string = "";

		for (const numberEmailToken of this._arrayNumberEmailToken)
		{
			if (numberEmailToken !== null && numberEmailToken !== undefined)
			{
				stringEmailToken += numberEmailToken.toString();
			}
			else
			{

			}
		}

		return stringEmailToken;
	}

	private getStringWhatsAppTokenFromForm(): string
	{
		let stringWhatsAppToken: string = "";

		for (const numberWhatsAppToken of this._arrayNumberWhatsAppToken)
		{
			if (numberWhatsAppToken !== null && numberWhatsAppToken !== undefined)
			{
				stringWhatsAppToken += numberWhatsAppToken.toString();
			}
			else
			{

			}
		}

		return stringWhatsAppToken;
	}

	private getStringSMSTokenFromForm(): string
	{
		let stringSMSToken: string = "";

		for (const numberSMSToken of this._arrayNumberSMSToken)
		{
			if (numberSMSToken !== null && numberSMSToken !== undefined)
			{
				stringSMSToken += numberSMSToken.toString();
			}
			else
			{

			}
		}

		return stringSMSToken;
	}

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	//endregion


	//#region LISTENER

	clickInputToken(numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			this._arrayElementInputEmailToken[numberIndex].focus();
			(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	clickInputTokenWhatsApp(numberIndex: number): void
	{
		if (this._arrayElementInputEmailTokenWhatsApp != null)
		{
			this._arrayElementInputEmailTokenWhatsApp[numberIndex].focus();
			(this._arrayElementInputEmailTokenWhatsApp[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	clickInputTokenSMS(numberIndex: number): void
	{
		if (this._arrayElementInputTokenSMS != null)
		{
			this._arrayElementInputTokenSMS[numberIndex].focus();
			(this._arrayElementInputTokenSMS[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	listenInputToken(event: KeyboardEvent, numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			if (event.code === "Backspace")
			{
				if (numberIndex > 0)
				{
					this._arrayElementInputEmailToken[numberIndex - 1].focus();
					(this._arrayElementInputEmailToken[numberIndex - 1] as HTMLInputElement).value = "";
				}
				else
				{

				}
			}
			else
			{
				if (numberIndex < this._arrayElementInputEmailToken.length - 1)
				{
					if (event.code === "Tab")
					{
						(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
					}
					else
					{
						this._arrayElementInputEmailToken[numberIndex + 1].focus();
						(this._arrayElementInputEmailToken[numberIndex + 1] as HTMLInputElement).value = "";
					}
				}
				else
				{
					this.callVerifyCustomerByEmail();
				}
			}
		}
		else
		{

		}
	}

	listenInputTokenWhatsApp(event: KeyboardEvent, numberIndex: number): void
	{
		if (this._arrayElementInputEmailTokenWhatsApp != null)
		{
			if (event.code === "Backspace")
			{
				if (numberIndex > 0)
				{
					this._arrayElementInputEmailTokenWhatsApp[numberIndex - 1].focus();
					(this._arrayElementInputEmailTokenWhatsApp[numberIndex - 1] as HTMLInputElement).value = "";
				}
				else
				{

				}
			}
			else
			{
				if (numberIndex < this._arrayElementInputEmailTokenWhatsApp.length - 1)
				{
					if (event.code === "Tab")
					{
						(this._arrayElementInputEmailTokenWhatsApp[numberIndex] as HTMLInputElement).value = "";
					}
					else
					{
						this._arrayElementInputEmailTokenWhatsApp[numberIndex + 1].focus();
						(this._arrayElementInputEmailTokenWhatsApp[numberIndex + 1] as HTMLInputElement).value = "";
					}
				}
				else
				{
					this.callVerifyCustomerByMobilePhone(this._stringWhatsApp);
				}
			}
		}
		else
		{

		}
	}

	listenInputTokenSMS(event: KeyboardEvent, numberIndex: number): void
	{
		if (this._arrayElementInputTokenSMS != null)
		{
			if (event.code === "Backspace")
			{
				if (numberIndex > 0)
				{
					this._arrayElementInputTokenSMS[numberIndex - 1].focus();
					(this._arrayElementInputTokenSMS[numberIndex - 1] as HTMLInputElement).value = "";
				}
				else
				{

				}
			}
			else
			{
				if (numberIndex < this._arrayElementInputTokenSMS.length - 1)
				{
					if (event.code === "Tab")
					{
						(this._arrayElementInputTokenSMS[numberIndex] as HTMLInputElement).value = "";
					}
					else
					{
						this._arrayElementInputTokenSMS[numberIndex + 1].focus();
						(this._arrayElementInputTokenSMS[numberIndex + 1] as HTMLInputElement).value = "";
					}
				}
				else
				{
					this.callVerifyCustomerByMobilePhone(this._stringSMS);
				}
			}
		}
		else
		{

		}
	}

	//#endregion


	//#region FUNCTION

	getTokenFromURLParameter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];

		return stringToken;
	}

	setShowFormSMS()
	{
		this._booleanFormSignInEmail = true;
		this._booleanFormSignInVerifyEmail = true;
		this._booleanFormSignInWhatsApp = true;
		this._booleanFormSignInVerifyWhatsApp = true;
		this._booleanFormSignInSMS = true;
		this._booleanFormSignInVerifySMS = false;

		this.callUpdateCustomerMobilePhoneBySMS(false);
	}

	setShowFormWhatsApp()
	{
		this._booleanFormSignInEmail = true;
		this._booleanFormSignInVerifyEmail = true;
		this._booleanFormSignInWhatsApp = true;
		this._booleanFormSignInVerifyWhatsApp = false;
		this._booleanFormSignInSMS = true;
		this._booleanFormSignInVerifySMS = true;

		this.callUpdateCustomerMobilePhone(false);
	}

	//#endregion


	//#region FUNTION INTERVAL

	setTimerOneMinute(minute)
	{
		// let minute = 1;
		let numberSecond: number = minute * 60;
		let textSecond: any = "0";
		let numberStatusSecond: number = 60;

		const stringPrefix = minute < 10 ? "0" : "";

		this.timerInterval = setInterval(() =>
		{
			numberSecond--;
			if (numberStatusSecond !== 0)
			{
				numberStatusSecond--;
			}
			else
			{
				numberStatusSecond = 59;
			}

			if (numberStatusSecond < 10)
			{
				textSecond = "0" + numberStatusSecond;
			}
			else
			{
				textSecond = numberStatusSecond;
			}

			this.textTimer = `${stringPrefix}${Math.floor(numberSecond / 60)}:${textSecond}`;

			if (numberSecond === 0)
			{
				clearInterval(this.timerInterval);

				if(this._booleanButtonEmailInterval === true)
				{
					this._booleanButtonEmailInterval = false;
				}
				else if(this._booleanButtonVerifyEmailInterval === true)
				{
					this._booleanButtonVerifyEmailInterval = false;
				}
				else if(this._booleanButtonVerifyWhatsAppInterval === true)
				{
					this._booleanButtonVerifyWhatsAppInterval = false;
				}
				else if(this._booleanButtonVerifySMSInterval === true)
				{
					this._booleanButtonVerifySMSInterval = false;
				}
			}
		}, 1000);
	}

	startInterval()
	{
		this.setTimerOneMinute(1);
	}

	//#endregion

}

//#endregion