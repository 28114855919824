//#region IMPORT

import { ENUM_DEVICE_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { FrequentlyAskedQuestionsAnswerModel } from "./frequentlyaskedquestionsanswer.model";
import { FrequentlyAskedQuestionsQuestionTypeModel } from "./frequentlyaskedquestionsquestiontype.model";

//#endregion


//#region CLASS

export class FrequentlyAskedQuestionsQuestionModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for frequently asked questions question model, used for BRINS' Website FAQ.
		Author: Billy Johannes.
		Created on : Tuesday, 12 April 2022. 			Updated on : Saturday, 18 March 2023.
		Created by : Billy Johannes.					Updated by : Dimas Dandy.
		Version : 1.0:0.
	*/

	ID?: number;
	Token?: string;

	FrequentlyAskedQuestionsQuestionTypeID?: number;
	FrequentlyAskedQuestionsQuestionSubtypeID?: number;
	Content?: string;
	Priority?: number;

	FrequentlyAskedQuestionsQuestionTypeName?: string;
	FrequentlyAskedQuestionsQuestionSubtypeName?: string;
	FrequentlyAskedQuestionsQuestionTypeDeviceType?: ENUM_DEVICE_TYPE;
	FrequentlyAskedQuestionsQuestionTypeCulture?: string;

	modelFrequentlyAskedQuestionsQuestionType?: FrequentlyAskedQuestionsQuestionTypeModel;
	listModelFrequentlyAskedQuestionsAnswer?: Array<FrequentlyAskedQuestionsAnswerModel>;

	/* Attribute - END */

	/* Constructor - START */

	constructor()
	{
		super();
		this.modelFrequentlyAskedQuestionsQuestionType = new FrequentlyAskedQuestionsQuestionTypeModel();
		this.listModelFrequentlyAskedQuestionsAnswer = [];
	}

	/* Constructor - END */
}

//#endregion