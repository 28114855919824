<div id="divBodyAbout" class="DivBodyContainer">
    <div class="DivContentMain">
		<div class="DivContentAbout">
			<div class="DivHeaderContent">
				<h1>{{_modelHeader.TitleFirst}}</h1><h1> {{_modelHeader.TitleSecond}}</h1>
			</div>
			<div class="DivContentDescription">
				<p>{{_modelHeader.Description}}</p>
			</div>
		</div>
        <div class="DivContentImage">
            <figure>
                <img *ngIf="_modelHeader.listModelHeaderGallery !== undefined && _modelHeader.listModelHeaderGallery.length > 0" [src]="getImageFromBroadcastURL(_modelHeader.listModelHeaderGallery[0].URL)">
				<img src="../../assets/icons/sakina/patternheader/icon_2.svg">
                <img src="../../assets/icons/sakina/patternheader/icon_3.svg">
                <img src="../../assets/icons/sakina/patternheader/icon_4.svg">
                <img src="../../assets/icons/sakina/patternheader/icon_1.svg">
            </figure>
        </div>
    </div>
</div>

<div id="divBodyNotification" class="DivBodyContainer">
	<div class="DivEditProfileFormContainer FlexGrow1 FlexShrink1">
		<section class="SectionHeader">
			<h4 id="textHeaderActivity" (click)="callSelectNotificationActivity();">Aktivitas</h4>
			<div class="DivVerticalLine"></div>
			<h4 id="textHeaderPromotion" (click)="callSelectNotificationPromotion();">Promosi</h4>
		</section>

		<section class="SectionContainer" *ngIf="_booleanActivity">
			<div class="DivNotification" *ngFor="let modelUserActivityHistory of _arrayModelUserActivityHistory" (click)="callUpdateUserActivityHistorySeen(modelUserActivityHistory.Token)"
			style="cursor: pointer;">
				<div class="DivIconBullet">
					<div class="DivBullet" *ngIf="modelUserActivityHistory.Seen === false"></div>
				</div>
				<div class="DivNotificationContent">
					<h5>{{ modelUserActivityHistory.Title }}</h5>
					<p>{{ modelUserActivityHistory.Content }}</p>

					<span *ngIf="modelUserActivityHistory.IsToday === true">{{ modelUserActivityHistory.CreatedOn | convertToTimeShort }}</span>

					<span *ngIf="modelUserActivityHistory.IsYesterday === true">Kemarin</span>

					<span *ngIf="modelUserActivityHistory.IsYear === true && modelUserActivityHistory.IsOneWeek === false && modelUserActivityHistory.IsYesterday === false && modelUserActivityHistory.IsToday === false">{{ modelUserActivityHistory.CreatedOn | convertToDateShort }}</span>

					<ng-container *ngIf="modelUserActivityHistory.IsOneWeek === true && modelUserActivityHistory.IsYesterday === false && modelUserActivityHistory.IsToday === false">
						<span *ngIf="modelUserActivityHistory.DayOfWeek === 0">Minggu</span>
						<span *ngIf="modelUserActivityHistory.DayOfWeek === 1">Senin</span>
						<span *ngIf="modelUserActivityHistory.DayOfWeek === 2">Selasa</span>
						<span *ngIf="modelUserActivityHistory.DayOfWeek === 3">Rabu</span>
						<span *ngIf="modelUserActivityHistory.DayOfWeek === 4">Kamis</span>
						<span *ngIf="modelUserActivityHistory.DayOfWeek === 5">Jumat</span>
						<span *ngIf="modelUserActivityHistory.DayOfWeek === 6">Sabtu</span>
					</ng-container>

					<span *ngIf="modelUserActivityHistory.IsYear === false && modelUserActivityHistory.IsOneWeek === false && modelUserActivityHistory.IsYesterday === false && modelUserActivityHistory.IsToday === false">{{ modelUserActivityHistory.CreatedOn | initiateWithoutUTC | convertToDateMedium }}</span>
				</div>
			</div>
		</section>

		<section class="SectionContainer" *ngIf="_booleanPromotion">
			<div class="DivNotification" *ngFor="let modelDiscount of _arrayModelDiscount; let numberIndex = index" >
				<div class="DivPromotionContent">
					<ng-container *ngFor="let modelDiscountGallery of modelDiscount.listModelDiscountGallery">
						<a href="https://insurtech.brins.web.brinesia.app/home/promotion" target="_blank">
							<img src="{{ getImageFromBroadcastURL(modelDiscountGallery.URL) }}" id="imagePromotionPicture" alt="imagePromotionPicture">
						</a>
					</ng-container>
					<div class="DivPromotionDateRow">
						<div class="DivPromotionDateColumn">
							<span>Tanggal Berlaku</span>
							<p>{{ modelDiscount.StartOn | convertToDateShort  }}</p>
						</div>
						<div class="DivPromotionDateColumn">
							<span>Tanggal Berakhir</span>
							<p>{{ modelDiscount.ExpiryOn | convertToDateShort }}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</div>
<app-footer></app-footer>