//#region IMPORT

import { ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class UserGalleryModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for formation model, used for link to division and position.
		Author: Ibrahim Aziz.
		Created on : Tuesday, 30 March 2021. 				Updated on : Tuesday, 30 March 2021.
		Created by : Ibrahim Aziz.							Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	UserID?: number;
	UserToken?: string;
	Size?: number;

	Name?: string;
	URL?: string;
	Type?: ENUM_PHOTO_TYPE;
	Order?: number;
	Width?: number;
	Height?: number;
	Format?: string;
	Note?: string;
	Data?: string;
	FileName?: string;
	Latitude?: number;
	Longitude?: number;
	Extension?: string;

	/* Attribute - END */

	//#region FUNCTION

	validateAdd(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Foto Profil");

		if (this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama tidak boleh kosong!";
		}
		else if (this.Format == null || this.Format === undefined)
		{
			modelResponse.MessageContent = "Format attachment tidak boleh kosong!";
		}
		else if (this.Size == null || this.Size === undefined || this.Size <= 0)
		{
			modelResponse.MessageContent = "Ukuran size attachment lebih besar dari pada 0 byte.";
		}
		else if (this.Extension == null || this.Extension === undefined || this.Extension === "")
		{
			modelResponse.MessageContent = "Attachment extension tidak boleh kosong!";
		}
		else if (StringConstant.ARRAY_VALUE_IMAGE.indexOf(this.Extension.toLowerCase()) === -1)
		{
			modelResponse.MessageContent = "Format file attachment tidak diperbolehkan!";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			const pipeConverterByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

			if (this.Extension == null || this.Extension === undefined)
			{

			}
			else
			{
				if (this.Extension.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_ZIP || this.Extension.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_RAR)
				{
					if (this.Size === null || this.Size === undefined)
					{

					}
					else
					{
						if (this.Size <= RuleConstant.NUMBER_FILE_COMPRESS_SIZEMAXIMUM)
						{
							modelResponse.State = ENUM_RESPONSE_STATE.Success;
						}
						else
						{
							modelResponse.MessageContent = "Maksimum ukuran file dengan tipe kompres adalah " + pipeConverterByteForPreview.transform(RuleConstant.NUMBER_FILE_COMPRESS_SIZEMAXIMUM) + ".";
						}
					}
				}
				else
				{
					if (this.Size === null || this.Size === undefined)
					{

					}
					else
					{
						if (this.Size <= RuleConstant.NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM)
						{
							modelResponse.State = ENUM_RESPONSE_STATE.Success;
						}
						else
						{
							modelResponse.MessageContent = "Maksimum ukuran file dengan tipe non kompres adalah " + pipeConverterByteForPreview.transform(RuleConstant.NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM) + ".";
						}
					}
				}
			}
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.Extension == null || this.Extension === undefined)
			{

			}
			else
			{
				if (this.Extension?.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_ZIP || this.Extension?.toLowerCase() === StringConstant.STRING_FORMAT_COMPRESS_RAR)
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
				else
				{
					if (this.Format === "")
					{
						modelResponse.MessageContent = "Format tidak boleh kosong.";
					}
					else
					{
						modelResponse.MessageContent = "Form is filled correctly.";
						modelResponse.State = ENUM_RESPONSE_STATE.Success;
					}
				}
			}
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion
}

//#endregion