//#region IMPORT

import { ENUM_AWARD_PRIORITY, ENUM_AWARD_TYPE } from "../constants/enum.constant";
import { AwardGalleryModel } from "./awardgallery.model";
import { BaseModel } from "./bases/base.model";
import { BRINSUpdateModel } from "./brinsupdate.model";

//#endregion


//#region CLASS

export class AwardModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for coverage model, used for package information and link to product.
		Author: Eka Saputra.
		Created on : Wednesday, 30 March 2022. 			Updated on : Wednesday, 30 March 2022.
		Created by : Eka Saputra.						Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;
	BRINSUpdateID?: number;

	Type?: ENUM_AWARD_TYPE;
	Priority?: ENUM_AWARD_PRIORITY;
	Date?: Date;

	modelBRINSUpdate?: BRINSUpdateModel;
	listModelAwardGallery?: Array<AwardGalleryModel>;


	constructor()
	{
		super();

		this.modelBRINSUpdate = new BRINSUpdateModel();
		this.listModelAwardGallery = [];
	}

	/* Attribute - END */
}

//#endregion