//#region CLASS

import { GeneralModel } from "./bases/general.model";

export class LocationModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for location model, used for link to other locations model, not mapped.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	ID?: number;
	CountryID?: number;
	CountryCode?: string;
	CountryName?: string;
	ProvinceID?: number;
	ProvinceCode?: string;
	ProvinceName?: string;
	CityID?: number;
	CityCode?: string;
	CityName?: string;
	SubDistrictID?: number;
	SubDistrictName?: string;
	SubDistrictCode?: string;
	VillageID?: number;
	VillageCode?: string;
	VillageName?: string;
	HamletCode?: string;
	NeighbourhoodCode?: string;
	PostalID?: number;
	PostalCode?: string;
	PostalName?: string;
	Longitude?: number;
	Latitude?: number;

	/* Attribute - END */
}

//#endregion
