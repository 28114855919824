//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { FrequentlyAskedQuestionsQuestionSubtypeModel } from "../models/frequentlyaskedquestionsquestionsubtype.model";
import { FrequentlyAskedQuestionsQuestionTypeModel } from "../models/frequentlyaskedquestionsquestiontype.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class FrequentlyAskedQuestionsService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectFrequentlyAskedQuestionsQuestionTypeByAttributesForCustomer(interfaceGeneralService: GeneralServiceInterface, modelFrequentlyAskedQuestionsQuestionType: FrequentlyAskedQuestionsQuestionTypeModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelFrequentlyAskedQuestionsQuestionType), WebAddressConstant.STRING_URL_FAQTYPE_SELECTFAQQUESTIONTYPEBYATTRIBUTESFORCUSTOMER);
	}

	selectFrequentlyAskedQuestionsQuestionTypeByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelFrequentlyAskedQuestionsQuestionType: FrequentlyAskedQuestionsQuestionTypeModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelFrequentlyAskedQuestionsQuestionType), WebAddressConstant.STRING_URL_FAQTYPE_SELECTFAQQUESTIONTYPEBYTOKENFORCUSTOMER);
	}

	selectFrequentlyAskedQuestionsQuestionSubtypeByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelFrequentlyAskedQuestionsQuestionSubtype: FrequentlyAskedQuestionsQuestionSubtypeModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelFrequentlyAskedQuestionsQuestionSubtype), WebAddressConstant.STRING_URL_FAQTYPE_SELECTFAQQUESTIONSUBTYPEBYTOKENFORCUSTOMER);
	}


	//#endregion
}

//#endregion