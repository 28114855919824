//#region IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-termsandcondition",
		templateUrl: "./termsandcondition.component.html",
		styleUrls: ["./termsandcondition.component.sass"]
	}
)

//#endregion


//#region CLASS

export class TermsAndConditionComponent extends BaseAuthourizeDetailComponent
{

	//#region DECLARATION


	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router)
	{
		super(routeActivated, location, serviceSession, router);

	}

	//#endregion


	//#region NGONINIT

	ngOnInit(): void
	{
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region NAVIGATION

	//#endregion


	//#region FUNCTION

	//#endregion


	//#region WEBSERVICES

	//#endregion


	//#region NAVIGATION

	goToLink(stringURL: string): void
	{
		window.open(stringURL, "_blank");
	}

	//#endregion

}

//#endregion
