//#region IMPORT

import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_GENDER_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { RegexFunction } from "src/app/functions/regex.function";
import { TableModel } from "src/app/models/bases/table.model";
import { BranchModel } from "src/app/models/branch.model";
import { HandshakeModel } from "src/app/models/handshake.model";
import { LocationModel } from "src/app/models/location.model";
import { PostalModel } from "src/app/models/postal.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { BranchService } from "src/app/services/branch.service";
import { CustomerService } from "src/app/services/customer.service";
import { PostalService } from "src/app/services/postal.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion



//#region COMPONENT

@Component
(
	{
		selector: "app-signup",
		templateUrl: "./signup.component.html",
		styleUrls: ["./signup.component.sass"]
	}
)

//#endregion



//#region CLASS

export class SignupComponent extends BaseAuthourizeDetailComponent implements OnInit
{
	//#region DECLARATION

	public _modelUserPublic: UserModel;
	public _modelUserPublicRequest: UserModel;
	public _modelPostal: PostalModel;
	public _modelBranch: BranchModel;
	public _modelTable: TableModel;

	public _numberConstant = NumberConstant;

	public _booleanFormSignInEmail: boolean;
	public _booleanFormGender: boolean;
	public _booleanPostalCodeValid: boolean;

	public _stringConstant = StringConstant;
	public _stringDownloadCategory: string = "";

	public _enumGenderType = ENUM_GENDER_TYPE;

	public _functionRegex: RegexFunction;

	public _dateNow: Date;

	public _arrayStringURL: Array<string> = [];
	public _arrayNumberEmailToken: Array<number | null> = [null, null, null, null, null , null];
	public _arrayModelBranch: Array<BranchModel>;
	private _arrayElementInputEmailToken: NodeListOf<HTMLElement> | null = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceCustomer: CustomerService, routeActivated: ActivatedRoute, location: Location, private _servicePostal: PostalService, serviceSession: SessionService, router: Router, private _serviceBranch: BranchService)
	{
		super(routeActivated, location, serviceSession, router);

		this._modelUserPublic = new UserModel();
		this._modelUserPublicRequest = new UserModel();
		this._modelPostal = new PostalModel();
		this._modelBranch = new BranchModel();
		this._modelTable = new TableModel();

		this._booleanFormSignInEmail = true;
		this._booleanFormGender = true;
		this._booleanPostalCodeValid = false;

		this._arrayModelBranch = [];

		this._dateNow = new Date();

		this._functionRegex = new RegexFunction();

		this._modelUserPublic.ProductPromotionConsent = false;
		this._modelUserPublic.DataSharingConsent = false;
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this.callSelectBranchForCustomer();

		this._serviceCustomer._modelUserObservable.subscribe((modelUser) =>
		{
			this._modelUserPublic.Email = modelUser.Email;
			this._modelUserPublic.MobilePhone = modelUser.MobilePhone;

			if (this._modelUserPublic.Email === null || this._modelUserPublic.Email === undefined || this._modelUserPublic.MobilePhone === null || this._modelUserPublic.MobilePhone === undefined)
			{
				this._router.navigate(["signin"]);
			}
			else
			{
			}
		});
	}

	//#endregion


	//#region WEB SERVICE

	callUpdateCustomerPersonalData(componentCurrent: this): void
	{
		this._modelUserPublic.modelDivision = undefined;
		this._modelUserPublic.MobilePhone = this._modelUserPublic.MobilePhone?.toString();
		this._modelUserPublic.setMobilePhonePrefixIndonesian();

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		let modelResponseValidation: ResponseModel = this._modelUserPublic.validateFormUser();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = this._modelUserPublic.validateFormUser();
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceCustomer.updateCustomerPersonalData
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});

							const modelUserResponse: UserModel = new UserModel();
							modelUserResponse.setModelFromString(modelResponse.Data);
							modelUserResponse.modelDivision = undefined;

							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

							if (modelUserResponse.modelHandshake !== undefined)
							{
								const modelHandshake: HandshakeModel = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
							}

							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;

							componentCurrent._serviceSession.setUserSession(modelUserSession);

							componentCurrent._router.navigate(["home"]);
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, this._modelUserPublic);
		}
	}

	public callSelectLocationByPostalCode(): void
	{
		const componentCurrent: SignupComponent = this;
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		if (componentCurrent._modelUserPublic.PostalCode !== null && componentCurrent._modelUserPublic.PostalCode !== undefined)
		{
			componentCurrent._modelPostal.Code = componentCurrent._modelUserPublic.PostalCode;
		}
		else
		{

		}

		this._servicePostal.selectLocationByPostalCodeForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelLocationResponse: LocationModel = new LocationModel();
						modelLocationResponse.setModelFromString(modelResponse.Data);

						componentCurrent._modelUserPublic.setLocationFromPostal(modelLocationResponse);
						componentCurrent._booleanPostalCodeValid = true;
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
					{
						componentCurrent._modelUserPublic.CountryName = "";
						componentCurrent._modelUserPublic.ProvinceName = "";
						componentCurrent._modelUserPublic.CityName = "";
					});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectLocationByPostalCode(); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelPostal);
	}

	public callSelectBranchForCustomer(): void
	{
		const componentCurrent: SignupComponent = this;
		componentCurrent._modelTable.Search = JSON.stringify(this._modelUserPublic.BranchName);
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		this._modelTable.RowPerPage = 100;

		this._serviceBranch.selectBranchForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);
						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelBranch = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelTable);
	}

	//#endregion


	//#region GETTER

	private getStringEmailTokenFromForm(): string
	{
		let stringEmailToken: string = "";

		for (const numberEmailToken of this._arrayNumberEmailToken)
		{
			if (numberEmailToken !== null && numberEmailToken !== undefined)
			{
				stringEmailToken += numberEmailToken.toString();
			}
			else
			{

			}
		}

		return stringEmailToken;
	}

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	getBirthDate(event): void
	{
		const maxDate: Date = this._dateNow;
		maxDate.setDate(maxDate.getDate() + 1);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Tanggal Lahir";
		modelResponse.MessageContent = "Tanggal lahir tidak boleh lebih dari hari ini!";

		if(event.value > maxDate)
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
		}
		else
		{
			this._modelUserPublic.BirthDate = event.value.clearUTC();
		}
	};

	//#endregion


	//#region LISTENER

	clickInputToken(numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			this._arrayElementInputEmailToken[numberIndex].focus();
			(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	selectPostalCode(): void
	{
		if(this._modelUserPublic.PostalCode?.length === this._numberConstant.NUMBER_COMPARE_VALUE_FIVE)
		{
			this.callSelectLocationByPostalCode();
		}
		else
		{

		}
	}

	//#endregion


	//#region FUNCTION

	getTokenFromURLParameter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];

		return stringToken;
	}

	callSumbitSignUp()
	{
		const stringMessage = "Sign Up\nApakah data yang Anda masukkan sudah benar?";

		if (confirm(stringMessage))
		{
			this.callUpdateCustomerPersonalData(this);
		}
		else
		{
		}
	}

	//#endregion


	//#region SETTER

	setGender(enumGender: ENUM_GENDER_TYPE): void
	{
		if(enumGender !== undefined)
		{
			this._modelUserPublic.Gender = enumGender;
		}
	}

	setBranch(modelBranch: BranchModel): void
	{
		this._modelUserPublic.BranchID = modelBranch.ID;
		// this._modelUserPublic.BranchCode = modelBranch.Code;
		// this._modelUserPublic.BranchName = modelBranch.Name;
	}

	setBooleanDataSharingConsent(event): void
	{
		this._modelUserPublic.DataSharingConsent = event.checked;
	}

	setBooleanProductPromotionConsent(event): void
	{
		this._modelUserPublic.ProductPromotionConsent = event.checked;
	}

	//#endregion
}

//#endregion