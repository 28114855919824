//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { CustomerModel } from "../models/customer.model";
import { UserModel } from "../models/user.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class CustomerService extends BaseService
{
	//#region OBSERVBLE

	public _modelUserObservable: Observable<UserModel>;
	public _behaviorSubjectUserModel: BehaviorSubject<UserModel>;

	//#endregion


	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);

		this._behaviorSubjectUserModel = new BehaviorSubject(new UserModel());
		this._modelUserObservable = this._behaviorSubjectUserModel.asObservable();
	}

	//#endregion


	//#region SETTER

	setUserModel(modelUser: UserModel)
	{
		this._behaviorSubjectUserModel.next(modelUser);
	}

	//#endregion


	//#region SELECT

	selectCustomerByProfileID(interfaceGeneralService: GeneralServiceInterface, modelCustomer: CustomerModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelCustomer), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYPROFILEID);
	}

	selectCustomerByPolicyNumber(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYNUMBER);
	}

	selectCustomerByLicensePlate(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYLICENSEPLATE);
	}

	selectCustomerByPolicyholderAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYHOLDERATTRIBUTES);
	}

	selectCustomerTicketByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERTICKETBYEMAIL);
	}

	selectCustomerByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYEMAIL);
	}

	selectProfileCustomerByAttributes(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_PROFILECUSTOMER);
	}

	selectCustomerByContact(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYCONTACT);
	}

	selectLocationByPostalCodeForCustomer(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_SELECTLOCATIONBYPOSTALCODEFORCUSTOMER);
	}

	//#endregion


	//#region BUSINESS

	verifyCustomerTicketByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_VERIFYCUSTOMERTICKETBYEMAIL);
	}

	verifyCustomerByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_VERIFYCUSTOMERBYEMAIL);
	}

	verifyCustomerByMobilePhone(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_VERIFYCUSTOMERMOBILEPHONE);
	}

	//#endregion


	//#region SIGN OUT

	signOut(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_SIGNOUT);
	}

	//#endregion


	//#region UPDATE

	updateCustomerMobilePhone(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_UPDATECUSTOMERMOBILEPHONE);
	}

	updateCustomerMobilePhoneBySMS(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_UPDATECUSTOMERMOBILEPHONEBYSMS);
	}

	updateCustomerPersonalData(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_UPDATECUSTOMERPERSONALDATA);
	}

	updateCustomerProfile(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_UPDATECUSTOMERPROFILE);
	}

	updateCustomerByContact(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_UPDATECUSTOMERBYCONTACT);
	}

	updateCustomerByContactSMS(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_UPDATECUSTOMERBYCONTACTSMS);
	}

	//#endregion


	//#region REGISTER

	registerCustomerByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_REGISTERCUSTOMERBYEMAIL);
	}

	//#endregion


	//#region UPLOAD

	uploadUserWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_USER_UPLOADUSERWEBSITECUSTOMER);
	}

	//#endregion

}

//#endregion