//#region IMPORT

import { ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_MARITAL_STATUS, ENUM_REGISTER_TYPE, ENUM_RELIGION_TYPE, ENUM_RESPONSE_STATE, ENUM_SALUTATION_TYPE, ENUM_USER_ROLE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { BranchModel } from "./branch.model";
import { CustomerModel } from "./customer.model";
import { DivisionModel } from "./division.model";
import { HandshakeModel } from "./handshake.model";
import { LocationModel } from "./location.model";
import { PositionModel } from "./position.model";
import { ResponseModel } from "./response.model";
import { UserGalleryModel } from "./usergallery.model";

//#endregion


//#region CLASS

export class UserModel extends BaseModel
{
	//#region ATTRIBUTE

	ID?: number;
	Salutation?: ENUM_SALUTATION_TYPE;
	FirstName?: string;
	MiddleName?: string;
	LastName?: string;
	FullName?: string;
	BirthDate?: Date;
	stringBirthDate?: string;
	BirthPlace?: string;
	Gender?: ENUM_GENDER_TYPE;
	MaritalStatus?: ENUM_MARITAL_STATUS;
	Religion?: ENUM_RELIGION_TYPE;
	NationalityID?: number;
	BranchID?: number;
	BranchCode?: string;
	BranchName?: string;
	PhotoURL?: string;

	Address?: string;
	CountryID?: number;
	CountryCode?: string;
	CountryName?: string;
	ProvinceID?: number;
	ProvinceCode?: string;
	ProvinceName?: string;
	CityID?: number;
	CityCode?: string;
	CityName?: string;
	SubDistrictID?: number;
	VillageID?: number;
	HamletCode?: string;
	NeighbourhoodCode?: string;
	PostalID?: number;
	PostalCode?: string;
	PostalName?: string;
	Latitude?: number;
	Longitude?: number;

	Telephone?: string;
	Fax?: string;
	MobilePhone?: string;
	MobilePhoneNew?: string;
	MobilePhoneToken?: string;
	Email?: string;
	EmailNew?: string;
	EmailToken?: string;
	URLValidation?: string;

	IdentificationNumber?: string;
	IdentificationType?: ENUM_IDENTIFICATION_TYPE;
	IdentificationName?: string;
	IdentificationURL?: string;
	Password?: string;
	PasswordNew?: string;
	PasswordConfirmation?: string;
	Role?: ENUM_USER_ROLE;
	DeviceID?: string;
	Key?: string;
	DataSharingConsent?: boolean;
	ProductPromotionConsent?: boolean;

	UserLevelID?: number;
	UserPreferenceID?: number;
	LastLoginOn?: Date;
	FailedAttemptLogin?: number;
	SignUpType?: ENUM_REGISTER_TYPE;
	SignUpID?: string;
	SignUpToken?: string;
	Signnumberype?: ENUM_REGISTER_TYPE;

	modelDivision?: DivisionModel;
	modelHandshake?: HandshakeModel;
	modelCustomer?: CustomerModel;
	modelPosition?: PositionModel;
	modelBranch?: BranchModel;
	Culture?: string;
	Token?: string;

	search?: string;
	DivisionID?: number;
	ProfileID?: string;
	CustomerCoreID?: string;
	PositionName?: string;

	listModelUserGallery?: Array<UserGalleryModel>;

	//#endregion

	constructor()
	{
		super();
		this.modelDivision = new DivisionModel();
		this.setModelDivison();
		this.setCustomerCoreID();
	}

	setModelDivison(): void
	{
		const stringModelDivision: string = JSON.stringify(this.modelDivision);
		this.modelDivision = new DivisionModel();
		this.modelDivision.setModelFromString(stringModelDivision);
	}

	setCustomerCoreID(): void
	{
		this.CustomerCoreID = this.ProfileID;
	}

	//#region GETTER

	getName(): string
	{
		if (this.FirstName == null || this.FirstName === undefined || this.FirstName === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringName: string = this.FirstName;

			if (this.MiddleName == null || this.MiddleName === undefined || this.MiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.MiddleName;
			}

			if (this.LastName == null || this.LastName === undefined || this.LastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.LastName;
			}

			return stringName;
		}
	}

	getNameInitial(): string
	{
		const arrayName: Array<string> = this.getName().split(StringConstant.STRING_CHARACTER_DASH);

		if (arrayName != null && arrayName !== undefined)
		{
			if (arrayName.length >= 2)
			{
				return arrayName[0].substring(0, 1) + arrayName[arrayName.length - 1].substring(0, 1);
			}
			else
			{
				return arrayName[0].substring(0, 2);
			}
		}
		else
		{
			return "";
		}
	}

	//#endregion

	//#region SETTER

	setLocationFromPostal(modelLocation: LocationModel): void
	{
		this.PostalCode = modelLocation.PostalCode;
		this.CityName = modelLocation.CityName;
		this.CountryName = modelLocation.CountryName;
		this.ProvinceName = modelLocation.ProvinceName;
	}

	setMobilePhonePrefixIndonesian(): void
	{
		let stringPrefixMobilePhone: string = "";

		if(this.MobilePhone != null || this.MobilePhone !== undefined || this.MobilePhone === "")
		{
			stringPrefixMobilePhone = this.MobilePhone?.substr(0, 1);
			if(stringPrefixMobilePhone === StringConstant.STRING_VALUE_MOBILEPHONE_PREFIXCODE)
			{
				const stringMobilePhone = this.MobilePhone?.substr(1, 11);
				this.MobilePhone = StringConstant.STRING_VALUE_MOBILEPHONE_PREFIXCODE_INDONESIAN + stringMobilePhone;
			}
		}
		if(this.MobilePhoneNew != null || this.MobilePhoneNew !== undefined || this.MobilePhoneNew === "")
		{
			stringPrefixMobilePhone = this.MobilePhoneNew?.substr(0, 1);
			if(stringPrefixMobilePhone === StringConstant.STRING_VALUE_MOBILEPHONE_PREFIXCODE)
			{
				const stringMobilePhone = this.MobilePhoneNew?.substr(1, 11);
				this.MobilePhoneNew = StringConstant.STRING_VALUE_MOBILEPHONE_PREFIXCODE_INDONESIAN + stringMobilePhone;
			}
		}
	}

	//#endregion

	//#region VALIDATION

	validateEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regexEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAILWITHOUTEMOJI);

		if (this.Email == null || this.Email === undefined || this.Email === "")
		{
			modelResponse.MessageContent = "Email tidak boleh kosong";
		}
		else if (this.Email !== undefined && !regexEmail.test(this.Email))
		{
			modelResponse.MessageContent = "Email tidak sesuai format.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateEmailNew(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);

		if (this.EmailNew == null || this.EmailNew === undefined || this.EmailNew === "")
		{
			modelResponse.MessageContent = "Email can't be empty";
		}
		else if (regularExpression.test(this.EmailNew))
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Enter a valid email.";
		}

		return modelResponse;
	}

	validateEmailToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.EmailToken == null || this.EmailToken === undefined || this.EmailToken === "")
		{
			modelResponse.MessageContent = "OTP Email can't be empty.";
		}
		else if (this.EmailToken.length !== RuleConstant.NUMBER_EMAILTOKEN_LENGTH)
		{
			modelResponse.MessageContent = "OTP Email should be " + RuleConstant.NUMBER_EMAILTOKEN_LENGTH + " characters length.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateMobilePhoneToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.MobilePhoneToken == null || this.MobilePhoneToken === undefined || this.MobilePhoneToken === "")
		{
			modelResponse.MessageContent = "OTP Mobile Phone can't be empty.";
		}
		else if (this.MobilePhoneToken.length !== RuleConstant.NUMBER_EMAILTOKEN_LENGTH)
		{
			modelResponse.MessageContent = "OTP Mobile Phone should be " + RuleConstant.NUMBER_EMAILTOKEN_LENGTH + " characters length.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateMobilePhone(): ResponseModel
	{
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.MobilePhone == null || this.MobilePhone === undefined || this.MobilePhone === "")
		{
			modelResponse.MessageContent = "MobilePhone can't be empty.";
		}
		else if (!regExNumeric.test(this.MobilePhone))
		{
			modelResponse.MessageContent = "Mobile phone can only contain numeric.";
		}
		else if ((this.MobilePhone.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM))
		{
			modelResponse.MessageContent = "Mobile phone minimum contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM + " characters.";
		}
		else if ((this.MobilePhone.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM))
		{
			modelResponse.MessageContent = "Mobile phone maximum contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM + " characters.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateMobilePhoneNew(): ResponseModel
	{
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.MobilePhoneNew == null || this.MobilePhoneNew === undefined || this.MobilePhoneNew === "")
		{
			modelResponse.MessageContent = "MobilePhone can't be empty.";
		}
		else if (!regExNumeric.test(this.MobilePhoneNew))
		{
			modelResponse.MessageContent = "Mobile phone can only contain numeric.";
		}
		else if ((this.MobilePhoneNew.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM))
		{
			modelResponse.MessageContent = "Mobile phone minimum contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM + " characters.";
		}
		else if ((this.MobilePhoneNew.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM))
		{
			modelResponse.MessageContent = "Mobile phone maximum contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM + " characters.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateFormUser(): ResponseModel
	{
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const regExAlphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAlphaNumericForAddress: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICFORADDRESS);

		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		this.validateEmail();

		if (this.FirstName == null || this.FirstName === undefined || this.FirstName === "")
		{
			modelResponse.MessageContent = "Nama depan tidak boleh kosong.";
		}
		else if (this.FirstName !== undefined && (!regExAlphabet.test(this.FirstName)))
		{
			modelResponse.MessageContent = "Format input Nama Depan mengandung karakter yang tidak diizinkan.";
		}
		else if (this.MiddleName !== undefined && (!regExAlphabet.test(this.MiddleName)))
		{
			modelResponse.MessageContent = "Format input Nama Tengah mengandung karakter yang tidak diizinkan.";
			this.MiddleName = undefined;
		}
		else if (this.LastName !== undefined && (!regExAlphabet.test(this.LastName)))
		{
			modelResponse.MessageContent = "Format input Nama Belakang mengandung karakter yang tidak diizinkan.";
			this.LastName = undefined;
		}
		else if (this.Gender == null || this.Gender === undefined)
		{
			modelResponse.MessageContent = "Jenis kelamin tidak boleh kosong.";
		}
		else if (this.BirthPlace == null || this.BirthPlace === undefined || this.BirthPlace === "")
		{
			modelResponse.MessageContent = "Tempat lahir tidak boleh kosong.";
		}
		else if (this.BirthPlace !== undefined && (!regExAlphabet.test(this.BirthPlace)))
		{
			modelResponse.MessageContent = "Format input Tempat Lahir mengandung karakter yang tidak diizinkan.";
		}
		else if (this.BirthDate == null || this.BirthDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal lahir tidak boleh kosong.";
		}
		else if (this.IdentificationNumber == null || this.IdentificationNumber === undefined || this.IdentificationNumber === "")
		{
			modelResponse.MessageContent = "KTP tidak boleh kosong.";
		}
		else if (!regExNumeric.test(this.IdentificationNumber))
		{
			modelResponse.MessageContent = "KTP hanya boleh angka.";
		}
		else if ((this.IdentificationNumber.length < RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH))
		{
			modelResponse.MessageContent = "KTP minimal " + RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH + " karakter.";
		}
		else if ((this.IdentificationNumber.length > RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH))
		{
			modelResponse.MessageContent = "KTP maksimal " + RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH + " karakter.";
		}
		else if (this.BranchID == null || this.BranchID === undefined)
		{
			modelResponse.MessageContent = "Cabang tidak boleh kosong.";
		}
		else if (this.MobilePhone == null || this.MobilePhone === undefined || this.MobilePhone === "")
		{
			modelResponse.MessageContent = "Nomor handphone tidak boleh kosong.";
		}
		else if (!regExNumeric.test(this.MobilePhone))
		{
			modelResponse.MessageContent = "Nomor Handphone hanya boleh angka.";
		}
		else if (this.PostalCode == null || this.PostalCode === undefined || this.PostalCode === "")
		{
			modelResponse.MessageContent = "Kode Pos tidak boleh kosong.";
		}
		else if (!regExNumeric.test(this.PostalCode))
		{
			modelResponse.MessageContent = "Kode Pos hanya boleh angka.";
		}
		else if(this.ProvinceName == null || this.ProvinceName === undefined || this.ProvinceName === "")
		{
			modelResponse.MessageContent = "Provinsi tidak boleh kosong.";
		}
		else if(this.CityName == null || this.CityName === undefined || this.CityName === "")
		{
			modelResponse.MessageContent = "Kota tidak boleh kosong.";
		}
		else if(this.CountryName == null || this.CountryName === undefined || this.CountryName === "")
		{
			modelResponse.MessageContent = "Lokasi tidak boleh kosong.";
		}
		else if (this.Address == null || this.Address === undefined || this.Address === "")
		{
			modelResponse.MessageContent = "Address tidak boleh kosong.";
		}
		else if (!regExAlphaNumericForAddress.test(this.Address))
		{
			modelResponse.MessageContent = "Format input Alamat mengandung karakter yang tidak diizinkan.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelUserGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	//endregion


	//#region CLEAR

	clearDivisionModel(): void
	{
		this.modelDivision = undefined;
	}

	//#endregion
}

//#endregion