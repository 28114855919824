//#region IMPORT

import { BaseModel } from "./bases/base.model";
import { DiscountGalleryModel } from "./discountgallery.model";

//#endregion


//#region CLASS

export class DiscountModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for discount.
		Author: Eka Saputra.
		Created on : Wednesday, 30 March 2022. 			Updated on : Friday, 17 Februaru 2023.
		Created by : Eka Saputra.						Updated by : Dimas Dandy Aryarajendra Suprapto.
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;
	Priority?: number;

	Code?: string;
	NameIDN?: string;
	NameUSA?: string;
	DescriptionIDN?: string;
	DescriptionUSA?: string;
	TermAndConditionIDN?: string;
	TermAndConditionUSA?: string;
	StartOn?: Date; // ActivedOn
	ExpiryOn?: Date; // ExpiredOn
	URL?: string;

	ProductCode?: string;
	ProductName?: string;
	CoverageCode?: string;
	CoverageName?: string;
	Percentage?: number;
	Amount?: number;
	Status?: string;
	UsageCount?: number;

	listModelDiscountGallery?: Array<DiscountGalleryModel>;
	listStringCoverageName?: Array<string>;

	constructor()
	{
		super();

		// this.listModelDiscountGallery = [];
	}

	/* Attribute - END */
}

//#endregion