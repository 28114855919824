<div id="divBodyAbout" class="DivBodyContainer">
	<div class="DivContentMain">
		<div class="DivContentAbout">
			<div class="DivHeaderContent">
				<h1>{{_modelHeader.TitleFirst}}</h1><h1> {{_modelHeader.TitleSecond}}</h1>
			</div>
			<div class="DivContentDescription">
				<p>{{_modelHeader.Description}}</p>
			</div>
		</div>
		<div class="DivContentImage">
			<figure>
                <img *ngIf="_modelHeader.listModelHeaderGallery !== undefined && _modelHeader.listModelHeaderGallery.length > 0" [src]="getImageFromBroadcastURL(_modelHeader.listModelHeaderGallery[0].URL)">
				<img src="../../assets/icons/sakina/patternheader/icon_2.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_3.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_4.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_1.svg">
			</figure>
		</div>
	</div>
</div>

<div id="divBodyEditProfile" class="DivBodyContainer">
	<div class="DivEditProfileFormContainer FlexGrow1 FlexShrink1">
		<form>
			<div class="DivForm">
				<div class="DivFormHeader">
					<h4>Personal</h4>
					<span>Satu langkah menuju perlindungan</span>
				</div>

				<div class="DivFormPhotoProfile">
					<div class="DivFormColumn">
						<label>Unggah Foto</label>
					</div>
					<div class="DivFormColumn">
						<figure>
							<div class="DivFormFigure">
								<ng-container *ngIf="_modelUserPublic.listModelUserGallery?.length === 0 || _modelUserPublic.listModelUserGallery === undefined">
									<img *ngIf="_modelUserPublicResponse.PhotoURL" [src]="_modelUserPublicResponse.PhotoURL" id="imageProfilePicture">
									<span *ngIf="!_modelUserPublicResponse.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
								</ng-container>

								<ng-container *ngIf="_modelUserPublic.listModelUserGallery?.length !== 0">
									<ng-container *ngFor="let modelUserPublic of _modelUserPublic.listModelUserGallery">
										<ng-container *ngIf="modelUserPublic.Data === undefined || modelUserPublic.Data === ''">
											<img [src]="getImage(modelUserPublic.URL)"id="imageProfilePicture">
										</ng-container>

										<ng-container *ngIf="modelUserPublic.Data !== undefined && modelUserPublic.Data !== ''">
											<img *ngIf="modelUserPublic.State === 0" [src]="modelUserPublic.Data" id="imageProfilePicture">
										</ng-container>
									</ng-container>
								</ng-container>
							</div>
						</figure>
					</div>
					<div class="DivFormColumn" id="divFormColumnButton">
						<input #fileAttachment type="file" (change)="listenFileUpload($event)">
						<label for="fileAttachment" class="LabelAttachment" id="labelAttachment">
							<input type="button" class="ButtonAttachment" (click)="fileAttachment.click();" value="Ganti Foto">
						</label>
					</div>
				</div>

				<fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<div class="DivFormHorizontal">
								<label for="textNamaDepan">Nama<br>Depan</label>
								<div class="DivInputHorizontalContainer" >
									<input type="text" id="textNamaDepan" name="modelUserFirstName" placeholder="Masukkan nama depan" [(ngModel)]="_modelUserPublic.FirstName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivPerson"></div>
								</div>
							</div>
							<hr id="hr1">
							<div class="DivFormHorizontal">
								<label for="textNamaTengah">Nama<br>Tengah</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textNamaTengah" name="modelUserMiddleName" placeholder="Masukkan nama tengah" [(ngModel)]="_modelUserPublic.MiddleName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivPerson"></div>
								</div>
							</div>
							<hr id="hr19">
						</div>
						<div class="DivForm2Column">
							<div class="DivFormHorizontal">
								<label for="textNamaBelakang">Nama Belakang</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textNamaBelakang" name="modelUserLastName" placeholder="Masukkan nama belakang" [(ngModel)]="_modelUserPublic.LastName" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivPerson"></div>
								</div>
							</div>
							<hr id="hr2">
						</div>
						<div class="DivForm2Column">
							<hr id="hr3">
							<div class="DivFormHorizontalRadioButton">
								<label for="radioJenisKelamin" id="labelJenisKelamin">Jenis<br>Kelamin</label>
								<div class="DivRadioButtonContainer">
									<label class="LabelRadioButtonContainer" for="radioJenisKelaminLakilaki" [ngClass]="{ 'LabelRadioButtonActive' : _modelUserPublic.Gender === this._enumGenderType.Male }" (click)="setGender(this._enumGenderType.Male)">
										<input type="radio" name="modelUserPublicGender" id="radioJenisKelaminLakilaki" [checked]="_modelUserPublic.Gender === this._enumGenderType.Male">Laki-laki
									</label>

									<label class="LabelRadioButtonContainer" for="radioJenisKelaminPerempuan" [ngClass]="{ 'LabelRadioButtonActive' : _modelUserPublic.Gender === this._enumGenderType.Female }" (click)="setGender(this._enumGenderType.Female)">
										<input type="radio" name="modelUserPublicGender" id="radioJenisKelaminPerempuan" [checked]="_modelUserPublic.Gender === this._enumGenderType.Female">Perempuan
									</label>
								</div>
							</div>
							<hr id="hr4">
						</div>
					</div>

					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<div class="DivFormHorizontal">
								<label for="textTempatLahir">Tempat<br>Lahir</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textTempatLahir" name="modelUserBirthPlace" placeholder="Masukkan tempat lahir" [(ngModel)]="_modelUserPublic.BirthPlace" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
									<div class="DivLocation"></div>
								</div>
							</div>
							<hr id="hr5">
							<div class="DivDateTimePicker">
								<label for="selectModelProductOtoVehicleManufactureYearCode">Tanggal<br>Lahir</label>
								<mat-form-field appearance="fill" class="Margin0"  (click)="dpReporterDate.open()">
									<mat-label>Pilih tanggal</mat-label>
									<input matInput [matDatepicker]="dpReporterDate" [value]="_modelUserPublic!.BirthDate" [max]="this._dateNow" (dateChange)="getBirthDate($event);" readonly>
									<mat-datepicker-toggle matSuffix [for]="dpReporterDate"></mat-datepicker-toggle>
									<mat-datepicker #dpReporterDate startView="month" panelClass="month-picker"></mat-datepicker>
								</mat-form-field>
							</div>
							<hr id="hr6">
							<div class="DivFormHorizontal">
								<label for="textKtp">KTP</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textKtp" name="modelUserIdentificationNumber" placeholder="Masukkan 16 digit nomor ktp" [(ngModel)]="_modelUserPublic.IdentificationNumber" maxlength="16" (keypress)="this._functionRegex.regexNumber($event)">
									<div class="DivKtp"></div>
								</div>
							</div>
							<hr id="hr7">
						</div>

						<div class="DivForm2Column">
							<div class="DivFormHorizontal">
								<label for="selectCabang">Cabang BRINS Terdekat</label>
								<div class="DivFormSingleDropdown" id="selectCabang">
									<ng-select class="NgSelectAsignee"
									[multiple]="false"
									[searchable]="true"
									[hideSelected]="true"
									[(ngModel)]=_modelBranchResponse.Name
									[ngModelOptions]="{standalone: true}"
									(ngModelChange)="setBranch(_modelBranchResponse.Name)"
									>
									<ng-option class="custom" [value]="modelBranch" *ngFor="let modelBranch of _arrayModelBranch; let numberIndex = index">
                                        {{ modelBranch.Name }}
                                    </ng-option>
								</ng-select>
								</div>
							</div>
							<hr id="hr8">
							<div class="DivFormHorizontal" id="divFormHorizontalBlank">
								<div class="DivBlank"></div>
							</div>
							<hr id="hr9">
							<div class="DivFormHorizontal" id="divFormHorizontalBlank">
								<div class="DivBlank"></div>
							</div>
							<hr id="hr10">
						</div>
					</div>
				</fieldset>
			</div>

			<div class="DivForm">
				<div class="DivFormHeader">
					<h4>Kontak</h4>
					<span>Satu langkah menuju perlindungan</span>
				</div>

				<fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<div class="DivFormHorizontalEmail">
								<div class="DivFormHorizontal">
									<label for="emailEmail">Email</label>
									<div class="DivInputHorizontalContainer">
										<input type="email" id="emailEmail" name="modelUserEmail" placeholder="Masukkan email Anda" [(ngModel)]="_modelUserPublic.Email" disabled>
									</div>
								</div>
							</div>
							<div class="DivFormHorizontalEmailResponsive">
								<div class="DivFormHorizontal">
									<label for="emailEmail">Email</label>
									<div class="DivInputHorizontalContainer">
										<input type="email" id="emailEmail" name="modelUserEmail" placeholder="Masukkan email Anda" [(ngModel)]="_modelUserPublic.Email" disabled>
									</div>
								</div>
								<div class="DivFormLabelHorizontal">
									<label for="spanEmail">Anda dapat mengganti email
										<span id="spanEmail" (click)="goToEditEmail(_modelUserPublic.Token)">&nbsp;disini</span>
									</label>
								</div>
							</div>
							<hr id="hr11">
							<div class="DivFormHorizontalMobilePhone">
								<div class="DivFormHorizontal">
									<label for="textNomorHp">Nomor Hp</label>
									<div class="DivInputHorizontalContainer">
										<input type="text" id="textNomorHp" name="modelUserPhone" placeholder="Masukkan nomor hp"
										[(ngModel)]="_modelUserPublic.MobilePhone" disabled (keypress)="this._functionRegex.regexNumber($event)">
									</div>
								</div>
							</div>
							<div class="DivFormHorizontalMobilePhoneResponsive">
								<div class="DivFormHorizontal">
									<label for="textNomorHp">Nomor Hp</label>
									<div class="DivInputHorizontalContainer">
										<input type="text" id="textNomorHp" name="modelUserPhone" placeholder="Masukkan nomor hp"
										[(ngModel)]="_modelUserPublic.MobilePhone" disabled (keypress)="this._functionRegex.regexNumber($event)">
									</div>
								</div>
								<div class="DivFormLabelHorizontal">
									<label for="spanNoHp">Anda dapat mengganti nomor handphone
										<span id="spanNoHp" (click)="goToEditMobilePhone(_modelUserPublic.Token)">&nbsp;disini</span>
									</label>
								</div>
							</div>
							<hr id="hr12">
							<div class="DivFormHorizontal">
								<label for="textKodePos">Kode Pos</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textKodePos" name="modelUserPostalCode" placeholder="Masukkan kode pos"
									[(ngModel)]="this._modelUserPublic!.PostalCode" (keyup)="selectPostalCode()" maxlength="5" (keypress)="this._functionRegex.regexNumber($event)">
								</div>
							</div>
							<hr id="hr13">
							<div class="DivFormHorizontal">
								<label for="textProvinsi">Provinsi</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textProvinsi" name="modelUserProvince"
									[(ngModel)]="this._modelUserPublic!.ProvinceName" disabled (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
								</div>
							</div>
							<hr id="hr14">
							<div class="DivFormHorizontal DivFormHorizontalAddress">
								<label for="textAlamat">Alamat</label>
								<div class="DivInputHorizontalContainer">
									<textarea id="textAlamat" name="modelUserAddress"  [(ngModel)]="this._modelUserPublic!.Address" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
								</div>
							</div>
						</div>
						<div class="DivForm2Column">
							<div class="DivFormLabelHorizontal DivFormHorizontalEmail">
								<label for="spanEmail">Anda dapat mengganti email
									<span id="spanEmail" (click)="goToEditEmail(_modelUserPublic.Token)">&nbsp;disini</span>
								</label>
							</div>
							<hr id="hr15">
							<div class="DivFormLabelHorizontal DivFormHorizontalMobilePhone">
								<label for="spanNoHp">Anda dapat mengganti nomor handphone
									<span id="spanNoHp" (click)="goToEditMobilePhone(_modelUserPublic.Token)">&nbsp;disini</span>
								</label>
							</div>
							<hr id="hr16">
							<div class="DivFormHorizontal">
								<label for="textKota">Kota</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textKota" name="modelUserCity" [(ngModel)]="this._modelUserPublic!.CityName" disabled (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
								</div>
							</div>
							<hr id="hr17">
							<div class="DivFormHorizontal">
								<label for="textLokasi">Lokasi</label>
								<div class="DivInputHorizontalContainer">
									<input type="text" id="textLokasi" name="modelUserLocation" [(ngModel)]="this._modelUserPublic!.CountryName" disabled (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">
								</div>
							</div>
							<hr id="hr18">
							<div class="DivFormHorizontal DivFormHorizontalAddressResponsive">
								<label for="textAlamat">Alamat</label>
								<div class="DivInputHorizontalContainer">
									<textarea id="textAlamat" name="modelUserAddress"  [(ngModel)]="this._modelUserPublic!.Address" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>

			<div class="DivForm MarginTop50">
				<div class="DivFormBubble MarginBottom15">
					<h6>Persetujuan penggunaan data nasabah (opsional).</h6>

					<div class="DivCheckBoxContainer MarginBottom15">
						<label class="LabelCheckboxContainer" for="checkboxApproveTheStatementFirst">
							<input type="checkbox" name="checkboxApproveTheStatementFirst" id="checkboxApproveTheStatementFirst" [(ngModel)]="_modelUserPublic.DataSharingConsent" (change)="setBooleanDataSharingConsent($event.target)">
							{{_stringConstant.STRING_APPROVAL_USE_CUSTOMER_DATA_FIRST}}
						</label>
					</div>
					<div class="DivCheckBoxContainer">
						<label class="LabelCheckboxContainer" for="checkboxApproveTheStatementSecond">
							<input type="checkbox" name="checkboxApproveTheStatementSecond" id="checkboxApproveTheStatementSecond" [(ngModel)]="_modelUserPublic.ProductPromotionConsent" (change)="setBooleanProductPromotionConsent($event.target)">
							{{_stringConstant.STRING_APPROVAL_USE_CUSTOMER_DATA_SECOND}}
						</label>
					</div>
				</div>

				<div class="DivFormBubble Margin0">
					<h6>Catatan</h6>
					<span>{{_stringConstant.STRING_APPROVAL_USE_CUSTOMER_DATA_NOTE}}</span>
				</div>
			</div>

			<div class="DivContentButtonDetail">
				<a class="ButtonForward" (click)="callUpdateCustomerProfile(this)">
					<span class="ButtonContent"></span>
					<svg width="13px" height="10px" viewBox="0 0 13 10">
						<path d="M1,5 L11,5"></path>
						<polyline points="8 1 12 5 8 9"></polyline>
					</svg>
				</a>
			</div>
		</form>
	</div>
</div>
<app-footer></app-footer>