//#region IMPORT

import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_NAVIGATIONMENU_BRINS } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { WebAddressConstant } from "src/app/constants/webaddress.constant";
import { DashboardNotificationModel } from "src/app/models/dashboardnotification.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { CustomerService } from "src/app/services/customer.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { SessionService } from "src/app/services/session.service";
import { HeaderModel } from "src/app/models/header.model";
import { HeaderService } from "src/app/services/header.service";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	public _modelDashboardNotification: DashboardNotificationModel;
	public _modelHeader: HeaderModel;

	public _enumNavigationMenu= ENUM_NAVIGATIONMENU_BRINS;

	public _booleanNavigationItem: boolean;
	public _booleanNavigationItem2: boolean;
	public _booleanNavigationItem3: boolean;
	public _booleanNavigationItem4: boolean;
	public _booleanMenuMaximize: boolean;
	public _booleanMenuBarMinimize: boolean;
	public _booleanChatMelisa: boolean;
	public _booleanMenuMini: boolean;

	public _numberPixelHeight: number;
	public _numberPixelWidth: number;

	public _stringConversation: string;

	public _arrayStringConversation: Array<string>;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _serviceCustomer: CustomerService, private _serviceDashboard: DashboardService, private _serviceHeader: HeaderService)
	{
		super(serviceSession, router);

		this._modelDashboardNotification = new DashboardNotificationModel();
		this._modelHeader = new HeaderModel();

		this._booleanMenuMaximize = true;
		this._booleanChatMelisa = false;
		this._booleanMenuBarMinimize = false;
		this._booleanMenuMini = false;
		this._booleanNavigationItem = false;
		this._booleanNavigationItem2 = false;
		this._booleanNavigationItem3 = false;
		this._booleanNavigationItem4 = false;

		this._numberPixelHeight = 0;
		this._numberPixelWidth = 0;

		this._stringConversation = "";

		this._arrayStringConversation = [];

		this.getScreenSize();
	}

	//#endregion


	//#region LISTENER

	@HostListener("window:resize", ["$event"])
	// eslint-disable-next-line no-unused-vars
	getScreenSize(event?): void
	{
		this._numberPixelHeight = window.innerHeight;
		this._numberPixelWidth = window.innerWidth;

		if(this._numberPixelWidth <= 800 || (this._numberPixelWidth === 1080 && this._numberPixelHeight === 1920))
		{
			this._booleanMenuMaximize = false;
		}
		else
		{
			this._booleanMenuMaximize = true;
		}
	}

	//#endregion


	//#region NGONINIT

	ngOnInit(): void
	{
		this.getScreenSize();

		if (this._modelUserSignIn.FirstName === null || this._modelUserSignIn.FirstName === undefined)
		{

		}
		else
		{
			this.callSelectNotificationForCustomer(this);
		}
	}

	//#endregion


	//#region NAVIGATION

	public goToAbout(): void
	{
		this._router.navigate(["home", "about"]);
	}

	public goToSocialValueGCG(): void
	{
		this._router.navigate(["home", "socialvalue"]);
	}

	public goToEconomicValue(): void
	{
		this._router.navigate(["home", "economicvalue"]);
	}

	public goToBRINSUpdate(): void
	{
		this._router.navigate(["home", "brinsupdate"]);
	}

	public goToBRINSUpdateNewsFeeds(): void
	{
		this._router.navigate(["home", "brinsupdate", "newsfeeds"]);
	}

	public goToBRINSUpdateCommunity(): void
	{
		this._router.navigate(["home", "brinsupdate", "community"]);
	}

	public goToBRINSUpdateFinancialLiteracy(): void
	{
		this._router.navigate(["home", "brinsupdate", "financialliteracy"]);
	}

	public goToBRINSUpdateTestimoni(): void
	{
		this._router.navigate(["home", "brinsupdate", "testimony"]);
	}

	public goToBRINSUpdateBRINSEditorial(): void
	{
		this._router.navigate(["home", "brinsupdate", "brinseditorial"]);
	}

	public goToProduct(): void
	{
		this._router.navigate(["home", "product"]);
	}

	public goToJoinUs(): void
	{
		this._router.navigate(["home", "joinus"]);
	}

	public goToHome(): void
	{
		this._router.navigate(["/home"]);
	}

	public goToCustomerCare(): void
	{
		this._router.navigate(["home", "customercare"]);
	}

	public goToCustomerCareSubmissionClaim(): void
	{
		this._router.navigate(["home", "customercare", "submissionclaim"]);
	}

	public goToCustomerCareTrackingClaim(): void
	{
		this._router.navigate(["home", "customercare", "trackingclaim"]);
	}

	public goToCustomerCarePartnerWorkshop(): void
	{
		this._router.navigate(["home", "customercare", "partnerworkshop"]);
	}

	public goToCorporateValues(): void
	{
		this._router.navigate(["home", "about", "corporatevalues"]);
	}

	public goToCompanyProfile(): void
	{
		this._router.navigate(["home", "about", "companyprofile"]);
	}

	public goToOrganizationAndStructure(): void
	{
		this._router.navigate(["home", "about", "organizationandstructure"]);
	}

	public goToAward(): void
	{
		this._router.navigate(["home", "about", "award"]);
	}

	public goToPartner(): void
	{
		this._router.navigate(["home", "about", "partnerandclient"]);
	}

	public goToContactAndLocation(): void
	{
		this._router.navigate(["home", "about", "contactandlocation"]);
	}

	public goToWorkshop(): void
	{
		this._router.navigate(["home", "customercare", "workshop"]);
	}

	public goToTrackingClaim(): void
	{
		this._router.navigate(["home", "customercare", "claimtracking"]);
	}

	public goToProfile(): void
	{
		this._router.navigate(["home", "editprofile"]);
	}

	public goToNotification(): void
	{
		this._router.navigate(["home", "notification"]);
	}

	public goToInsurtech(): void
	{
		window.open(WebAddressConstant.STRING_URL_BRINSDIGITAL_WEB + "/home", "_blank");
	}

	public signIn(): void
	{
		this._router.navigate(["signin"]);
	}

	public signOut(): void
	{
		const stringMessage = "Sign In\nApakah Anda ingin keluar dari akun?";
		if (confirm(stringMessage) === true)
		{
			this._serviceSession.clearKey();
			this._serviceSession.clearSession();
			this._modelDashboardNotification.UnseenNotification = undefined;

			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Sign In";
			modelResponse.MessageContent = "Sukses! Anda telah keluar dari akun!";
			this._router.navigate([""]).then(() => { window.location.reload(); }).catch();
			// this._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>
			// {
			// 	this._router.navigate([""]).then(() => { window.location.reload(); });
			// });
		}
		else
		{
		}


	}

	//#endregion


	//#region SCROLL

	public scrolled(event: any): void
	{
		if(this._booleanMenuMaximize)
		{
			const top = document.getElementById("divMainDashboard");
			if (top !== null)
			{
				if(event.srcElement.scrollTop <= 200)
				{
					this._booleanMenuMini = false;
				}
				else
				{
					this._booleanMenuMini = true;
				}
			}
		}
	}

	public minimizeMenu(booleanMenu: boolean): void
	{
		if(this._booleanMenuMini)
		{
			this._booleanMenuMini = booleanMenu;
		}
		else
		{
			if(this._booleanMenuMaximize === true)
			{
			}
			else
			{
				this._booleanMenuBarMinimize = booleanMenu;
				this._booleanNavigationItem = false;
				this._booleanNavigationItem2 = false;
				this._booleanNavigationItem3 = false;
				this._booleanNavigationItem4 = false;
			}
		}
	}

	//#endregion


	//#region FUNCTION

	public setBooleanChatMelisa(booleanMenu: boolean): void
	{
		this._booleanChatMelisa = booleanMenu;
	}

	public setConvertionArray(): void
	{
		if(this._stringConversation === null || this._stringConversation === undefined || this._stringConversation === "")
		{

		}
		else
		{
			this._arrayStringConversation.push(this._stringConversation);
			this._stringConversation = "";
		}
	}

	public setActiveNavigation(enumAboutBRINS: ENUM_NAVIGATIONMENU_BRINS): boolean
	{

		if(enumAboutBRINS === this._enumNavigationMenu.Home)
		{
			if(this._router.url === "/home")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.AboutUs)
		{
			if(this._router.url === "/home/about")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.SocialValue)
		{
			if(this._router.url === "/home/socialvalue")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.EconomicValue)
		{
			if(this._router.url === "/home/economicvalue")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.BRINSUpdate)
		{
			if(this._router.url === "/home/brinsupdate" || this._router.url === "/home/brinsupdate/newsfeeds" || this._router.url === "/home/brinsupdate/community" || this._router.url === "/home/brinsupdate/article" || this._router.url === "/home/brinsupdate/testimony" || this._router.url === "/home/brinsupdate/financialliteracy")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.CustomerCare)
		{
			if(this._router.url === "/home/customercare" || this._router.url === "/home/customercare/submissionclaim" || this._router.url === "/home/customercare/workshop")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.JoinUs)
		{
			if(this._router.url === "/home/joinus")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.AMS)
		{
			if(this._router.url === "/home/ams")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	//#endregion


	//#region WEBSERVICE

	public callSignOut(): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			this._serviceCustomer.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new UserModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	public callSelectNotificationForCustomer(componentCurrent: HomeComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		this._serviceDashboard.selectNotificationForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelDashboardNotification.setModelFromString(modelResponse.Data);
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.updateLoadingProgress();
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, this._modelDashboardNotification);
	}

	public callSelectHeaderWithSubHeader(componentCurrent: HomeComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelHeader: HeaderModel = new HeaderModel();

		this._serviceHeader.selectHeaderWithSubHeader
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelHeader.setModelFromString(modelResponse.Data);
					}
					else
					{
					}
				}
				else
				{
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {componentCurrent.callSelectHeaderWithSubHeader(componentCurrent);});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, modelHeader);
	}

	//#endregion

}

//#endregion
