<div id="divMainTermsAndCondition">
    <div class="DivHeaderPolicy">
        <img id="imgHeaderShape" src="../assets/shapes/sakina/shape_faq_friend.svg" >
		<img id="imgHeaderResponsive" src="../assets/shapes/sakina/shape_faq_friend_mobile_responsive.svg" >
        <h1>FAQ</h1>
    </div>

    <div class="DivSiteContainer DivMainPolicy">
        <section>
            <h1>Kategori</h1>
            <div class="DivButton2Column">
                <ng-container *ngFor="let modelFAQQuestionType of _arrayModelFAQQuestionType; let numberIndex = index">
                    <button [ngClass]="{'ButtonActive': _numberFAQActive === numberIndex+1}" (click)="callSelectFrequentlyAskedQuestionsQuestionTypeByToken(this, modelFAQQuestionType, numberIndex+1)"><label>{{ modelFAQQuestionType.Name }}</label></button>
                </ng-container>
            </div>
        </section>

        <section *ngIf="this._numberFAQActive !== this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;">
            <h1>Daftar Pertanyaan - {{ _modelFAQQuestionTypeResponse.Name }}</h1>
            <article>
				<div class="DivDetailFAQ" *ngIf="_modelFAQQuestionTypeResponse.listModelFrequentlyAskedQuestionsQuestionSubtype !== undefined && _modelFAQQuestionTypeResponse.listModelFrequentlyAskedQuestionsQuestionSubtype.length > 0">
                    <div class="DivButton2Column MarginBottom30">
                        <ng-container *ngFor="let modelFAQQuestion of _modelFAQQuestionTypeResponse.listModelFrequentlyAskedQuestionsQuestionSubtype; let numberIndex = index">
                            <button [ngClass]="{'ButtonActive': _numberFAQProduct === numberIndex+1}" (click)="callSelectFrequentlyAskedQuestionsQuestionSubtypeByToken(this, modelFAQQuestion, numberIndex+1)"><label>{{ modelFAQQuestion.Name }}</label></button>
                        </ng-container>
                    </div>
					<div class="DivDetailFAQ" *ngFor="let modelFAQQuestionSubtype of _modelFAQQuestionSubtypeResponse.listModelFrequentlyAskedQuestionsQuestion; let numberIndexProduct=index;">
						<input type="checkbox" name="CheckBoxProductContentTrigger" id="checkBoxFAQProductContent{{numberIndexProduct}}">
	
						<label for="checkBoxFAQProductContent{{numberIndexProduct}}" class="LabelContainerFAQQuestion">
							<div class="DivHorizontalContainer">
								<label for="checkBoxFAQProductContent{{numberIndexProduct}}" [innerHTML]="modelFAQQuestionSubtype.Content"></label>
								<img class="ImgFAQQuestion">
							</div>
						</label>
	
						<ng-container *ngFor="let modelFAQProductAnswer of modelFAQQuestionSubtype.listModelFrequentlyAskedQuestionsAnswer">
							<p class="ParagraphFAQAnswer" [innerHTML]="modelFAQProductAnswer.Content"></p>
						</ng-container>
						<hr>
					</div>
                </div>

				<ng-container *ngIf="_modelFAQQuestionTypeResponse.listModelFrequentlyAskedQuestionsQuestion !== undefined && _modelFAQQuestionTypeResponse.listModelFrequentlyAskedQuestionsQuestionSubtype?.length === 0">
					<div class="DivDetailFAQ" *ngFor="let modelFAQQuestion of _modelFAQQuestionTypeResponse.listModelFrequentlyAskedQuestionsQuestion; let numberIndex=index;">
						<input type="checkbox" name="CheckBoxContentTrigger" id="checkBoxFAQContent{{numberIndex}}">
	
						<label for="checkBoxFAQContent{{numberIndex}}" class="LabelContainerFAQQuestion">
							<div class="DivHorizontalContainer">
								<label for="checkBoxFAQContent{{numberIndex}}" [innerHTML]="modelFAQQuestion.Content"></label>
								<img class="ImgFAQQuestion">
							</div>
						</label>
	
						<ng-container *ngFor="let modelFAQAnswer of modelFAQQuestion.listModelFrequentlyAskedQuestionsAnswer">
							<p class="ParagraphFAQAnswer" [innerHTML]="modelFAQAnswer.Content"></p>
						</ng-container>
						<hr>
					</div>
				</ng-container>
            </article>
        </section>
    </div>
</div>

<app-footer></app-footer>