//#region IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { RegexFunction } from "src/app/functions/regex.function";
import { ContentModel } from "src/app/models/content.model";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-search",
		templateUrl: "./search.component.html",
		styleUrls: ["./search.component.sass"],
	}
)
export class SearchComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _enumStringConstant = StringConstant;
	public _stringConstant = StringConstant;
	public _arrayNavigationMenu: Array<ContentModel>;
	public _arrayModelContentTemporary: Array<ContentModel>;
	public _stringInputValue: string;
	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTUR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router)
	{
		super(routeActivated, location, serviceSession, router);

		this._stringInputValue = "";
		this._arrayModelContentTemporary = [];
		this._functionRegex = new RegexFunction();

		this._arrayNavigationMenu =
		[
			{
				Name: "Tentang Kami",
				Description: "PT. BRI Asuransi Indonesia / BRI Insurance atau yang dikenal dengan BRINS merupakan perusahaan asuransi umum yang menyediakan solusi perlindungan aset (asuransi kerugian) untuk masyarakat maupun korporasi yang inovatif dan terpercaya",
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/aboutus/background.png",
				Path2: "about",
			},
			{
				Name: "Tentang Kami - Profil Perusahaan",
				Description: "Perjalanan BRINS sejak tahun 1989 berikan perlindungan terpercaya.",
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/companyprofile/header_company.png",
				Path2: "about",
				Path3: "companyprofile",
			},
			{
				Name: "Tentang Kami - Struktur Organisasi",
				Description: "Didukung dengan Struktur Organisasi yang kuat dan manajemen yang profesional untuk menghasilkan kinerja terbaik.",
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/organizationstructure/header_struktur.jpg",
				Path2: "about",
				Path3: "organizationandstructure",
			},
			{
				Name: "Tentang Kami - Penghargaan",
				Description: "Pencapaian BRINS hasil semangat & kinerja terbaik dari tahun ke tahun.",
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/award/header_award.jpg",
				Path2: "about",
				Path3: "award",
			},
			{
				Name: "Tentang Kami - Nilai Perusahaan",
				Description: "5 nilai perusahaan yang menjadi landasan seluruh pekerja BRINS untuk mencapai tujuan perusahaan.",
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/corporatevalue/header_corporatevalue.jpg",
				Path2: "about",
				Path3: "corporatevalues",
			},
			{
				Name: "Tentang Kami - Mitra",
				Description: "Kerjasama BRINS dengan berbagai mitra usaha untuk memberikan perlindungan terbaik dan menyeluruh.",
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/partner/background.jpg",
				Path2: "about",
				Path3: "partnerandclient",
			},
			{
				Name: "Tentang Kami - Kontak dan Lokasi",
				Description: "Tersebar di seluruh Indonesia, BRINS memiliki 22 Kantor Cabang Konvensional, 2 Kantor Cabang Syariah, 8 Marketing Representative Office, 2 Representative Office Syariah, 45 Marketing Channel, 12 Marketing Office Syariah.",
				Path:environment.URL_BROADCAST_API+"/Website/assets/background/sakina/contactlocation/header_contact.jpg",
				Path2: "about",
				Path3: "contactandlocation",
			},
			{
				Name: "Nilai Sosial",
				Description: "Komitmen BRINS menjaga keseimbangan di setiap aspek dengan mengedepankan nilai sosial.",
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/socialvalue/background.jpg",
				Path2: "socialvalue",
			},
			{
				Name: "Nilai Ekonomi",
				Description: "Komitmen BRINS menjunjung tinggi economic value yang berkelanjutan sebagai salah satu motor penggerak perekonomian nasional.",
				Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/economicvalue/header_economic.jpg",
				Path2: "economicvalue",
			},
		];
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		const stringToken: string | null = this.getParameterFromRouter();

		if (stringToken != null)
		{
			this._stringInputValue = stringToken;
			this.searchByValue();
		}
		else
		{

		}

	}

	//#endregion


	//#region AFTERVIEWINIT


	//#endregion


	//#region FUNCTION

	trackSearch(index, modelSearch: ContentModel)
	{
		return modelSearch ? modelSearch.ID : undefined;
	}

	searchByValue(): void
	{
		this._arrayModelContentTemporary = [];
		if(this._stringInputValue !== null && this._stringInputValue !== undefined && this._stringInputValue !== "")
		{
			const arrayModelContentTemporary: Array<ContentModel> = this._arrayNavigationMenu.filter(o =>
				Object.keys(o).some(stringField => o[stringField].toLowerCase().includes(this._stringInputValue.toLowerCase())));

			arrayModelContentTemporary.forEach(element =>
			{
				this._arrayModelContentTemporary.push(element);
			});
		}

	}

	//#endregion


	//#region NAVIGATION

	goToSearch(stringPath1: string, stringPath2: string | null,): void
	{
		if((stringPath1 != null || stringPath1 !== undefined || stringPath1 !== "") && (stringPath2 != null || stringPath2 !== undefined || stringPath2 !== undefined))
		{
			this._router.navigate(["home", stringPath1, stringPath2]);
		}
		else
		{
			if(stringPath1 == null || stringPath1 === undefined || stringPath1 === "")
			{

			}
			else if(stringPath2 == null || stringPath2 === undefined || stringPath2 === "")
			{
				this._router.navigate(["home", stringPath1]);
			}
		}
	}

	//#endregion

}
