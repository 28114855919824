// #region  IMPORT


//#endregion


//#region  CLASS

export class RegexFunction
{
	//#region KEYPRESS REGEX

	public regexAlfabetWithSpace(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^[a-zA-Z ]+$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexNumber(event)
	{
		const regularExpressionNumber: RegExp = /^[0-9]*$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionNumber.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexEmail(event)
	{
		const regularExpressionEmail: RegExp = /^[A-Z0-9a-z@._]$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionEmail.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexNumberWithDash(event)
	{
		const regularExpressionNumberWithDash: RegExp = /^[0-9\-\s]+$/i;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionNumberWithDash.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexAlfanumericSpecialCharacter(event)
	{
		const regularExpressionAlfanumericSpecialCharacter: RegExp = /^[A-Z0-9a-z.\-_\s]+$/i;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfanumericSpecialCharacter.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexAlfanumeric(event)
	{
		const regularExpressionAlfanumericSpecialCharacter: RegExp = /^[A-Z0-9a-z]+$/i;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfanumericSpecialCharacter.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	//#endregion
}

// #endregion