//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class UserActivityHistoryModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for formation model, used for link to division and position.
		Author: Ibrahim Aziz.
		Created on : Tuesday, 30 March 2021. 				Updated on : Tuesday, 30 March 2021.
		Created by : Ibrahim Aziz.							Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	Title?: string;
	Content?: string;
	Token?: string;
	Seen?: boolean;

	/* Attribute - END */
}

//#endregion