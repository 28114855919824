//#region IMPORT

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { CryptographyFunction } from "src/app/functions/cryptography.function";
import { HandshakeModel } from "src/app/models/handshake.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { CustomerService } from "src/app/services/customer.service";
import { SessionService } from "src/app/services/session.service";
import { RegexFunction } from "src/app/functions/regex.function";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-editmobilephone",
		templateUrl: "./editmobilephone.component.html",
		styleUrls: ["./editmobilephone.component.sass"]
	}
)

//#endregion



//#region CLASS

export class EditmobilephoneComponent extends BaseAuthourizeComponent implements OnInit
{
	//#region DECLARATION

	public _modelUserPublic: UserModel;
	public _modelUserPublicRequest: UserModel;

	public _booleanFormWhatsApp: boolean | null;
	public _booleanFormVerifyWhatsApp: boolean | null;
	public _booleanFormSMS: boolean | null;
	public _booleanFormVerifySMS: boolean | null;
	public _booleanButtonVerifyWhatsAppInterval: boolean | null;
	public _booleanButtonVerifySMSInterval: boolean | null;

	public _enumStringConstant = StringConstant;

	public _stringDownloadCategory: string = "";
	public _stringWhatsApp: string = "WhatsApp";
	public _stringSMS: string = "SMS";

	public _arrayStringURL: Array<string> = [];
	public _arrayNumberWhatsAppToken: Array<number | null> = [null, null, null, null, null , null];
	public _arrayNumberSMSToken: Array<number | null> = [null, null, null, null, null , null];c;

	public textTimer: any;
	public timerInterval: any;

	public _functionRegex: RegexFunction;
	private _functionCryptography: CryptographyFunction;

	private _arrayElementInputWhatsAppToken: NodeListOf<HTMLElement> | null = null;
	private _arrayElementInputSMSToken: NodeListOf<HTMLElement> | null = null;


	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceCustomer: CustomerService, serviceSession: SessionService, router: Router, private _routeActivated: ActivatedRoute)
	{
		super(serviceSession, router);

		this._modelUserPublic = new UserModel();
		this._modelUserPublicRequest = new UserModel();

		this._functionRegex = new RegexFunction();
		this._functionCryptography = new CryptographyFunction();

		this._booleanFormWhatsApp = null;
		this._booleanFormVerifyWhatsApp = null;
		this._booleanFormSMS = null;
		this._booleanFormVerifySMS = null;
		this._booleanButtonVerifyWhatsAppInterval = false;
		this._booleanButtonVerifySMSInterval = false;
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this._arrayElementInputWhatsAppToken = document.querySelectorAll(".InputTokenWhatsapp");
		this._arrayElementInputSMSToken = document.querySelectorAll(".InputTokenSMS");

		const stringToken: string | null = this.getParameterFromRouter();

		if (stringToken != null)
		{
			this._modelUserPublic.Token = stringToken;
		}
		else
		{
			this.goToHome();
		}

		this._booleanFormWhatsApp = false;
		this._booleanFormVerifyWhatsApp = true;
		this._booleanFormSMS = true;
		this._booleanFormVerifySMS = true;
	}

	ngOnDestroy(): void
	{
		clearInterval(this.timerInterval);
	}

	//#endregion


	//#region WEB SERVICE

	callUpdateCustomerByContact(booleanResend: boolean): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		this._modelUserPublicRequest.modelDivision = undefined;
		this._modelUserPublicRequest.modelBranch = undefined;
		this._modelUserPublicRequest.ID = this._modelUserPublic.ID;
		this._modelUserPublicRequest.MobilePhone = this._modelUserSignIn.MobilePhone;
		this._modelUserPublicRequest.MobilePhoneNew = this._modelUserPublic.MobilePhone;
		this._modelUserPublicRequest.setMobilePhonePrefixIndonesian();
		this._modelUserPublicRequest.Token = this.getTokenFromURLParameter();

		const modelResponseValidation: ResponseModel = this._modelUserPublicRequest.validateMobilePhoneNew();
		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: EditmobilephoneComponent = this;

			this._serviceCustomer.updateCustomerByContact
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS )
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormVerifyWhatsApp = booleanResend;
							componentCurrent._booleanFormWhatsApp = true;

							//clear interval time
							clearInterval(componentCurrent.timerInterval);
							componentCurrent.textTimer = undefined;
							componentCurrent._booleanButtonVerifySMSInterval = false;

							//set interval 1 minutes for resend WhatsApp OTP
							componentCurrent._booleanButtonVerifyWhatsAppInterval = true;
							componentCurrent.startInterval();
						});
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callUpdateCustomerByContact(!booleanResend); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, this._modelUserPublicRequest);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	callUpdateCustomerByContactSMS(booleanResend: boolean): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		this._modelUserPublicRequest.modelDivision = undefined;
		this._modelUserPublicRequest.modelBranch = undefined;
		this._modelUserPublicRequest.ID = this._modelUserPublic.ID;
		this._modelUserPublicRequest.MobilePhone = this._modelUserSignIn.MobilePhone;
		this._modelUserPublicRequest.MobilePhoneNew = this._modelUserPublic.MobilePhone;
		this._modelUserPublicRequest.setMobilePhonePrefixIndonesian();
		this._modelUserPublicRequest.Token = this.getTokenFromURLParameter();

		const modelResponseValidation: ResponseModel = this._modelUserPublicRequest.validateMobilePhoneNew();
		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: EditmobilephoneComponent = this;

			this._serviceCustomer.updateCustomerByContactSMS
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS )
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._booleanFormVerifySMS = booleanResend;
							componentCurrent._booleanFormSMS = true;

							//clear interval time
							clearInterval(componentCurrent.timerInterval);
							componentCurrent.textTimer = undefined;
							componentCurrent._booleanButtonVerifyWhatsAppInterval = false;


							//set interval 1 minutes for resend SMS OTP
							componentCurrent._booleanButtonVerifySMSInterval = true;
							componentCurrent.startInterval();
						});
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}

					componentCurrent._functionUserInterface.updateLoadingProgress();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callUpdateCustomerByContactSMS(!booleanResend); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, this._modelUserPublicRequest);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	private callVerifyCustomerByMobilePhone(stringVerifyType: string): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.Token = this.getTokenFromURLParameter();

		if(stringVerifyType === this._stringWhatsApp)
		{
			modelUser.MobilePhoneToken = this.getStringWhatsAppTokenFromForm();
		}
		if(stringVerifyType === this._stringSMS)
		{
			modelUser.MobilePhoneToken = this.getStringSMSTokenFromForm();
		}

		modelUser.MobilePhone = this._modelUserSignIn.MobilePhone;
		modelUser.MobilePhoneNew = this._modelUserPublic.MobilePhone;
		modelUser.setMobilePhonePrefixIndonesian();

		let modelResponseValidation: ResponseModel = modelUser.validateMobilePhoneToken();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelUser.validateMobilePhoneToken();
		}
		else
		{

		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: EditmobilephoneComponent = this;

			if(stringVerifyType === this._stringWhatsApp)
			{
				modelUser.MobilePhoneToken = this._functionCryptography.hashWithSHA256((this.getStringWhatsAppTokenFromForm()));
			}
			if(stringVerifyType === this._stringSMS)
			{
				modelUser.MobilePhoneToken = this._functionCryptography.hashWithSHA256((this.getStringSMSTokenFromForm()));
			}

			this._serviceCustomer.verifyCustomerByMobilePhone
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.Data !== undefined)
					{
						const modelUserResponse: UserModel = new UserModel();
						modelUserResponse.setModelFromString(modelResponse.Data);
						modelUserResponse.modelDivision = undefined;

						if (modelUserResponse != null)
						{
							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

							if (modelUserResponse.modelHandshake !== undefined)
							{
								const modelHandshake: HandshakeModel = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
							}

							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;

							componentCurrent._serviceSession.setUserSession(modelUserSession);

							modelResponse.MessageTitle = "Mobile Phone";
							modelResponse.MessageContent = "Sukses update nomor handphone!";
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._router.navigate(["home", "editprofile"]);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
						}
					}
					else
					{
						componentCurrent._router.navigate(["home", "editprofile"]);
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callVerifyCustomerByMobilePhone(stringVerifyType); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	//#endregion


	//#region GETTER

	private getStringWhatsAppTokenFromForm(): string
	{
		let stringWhatsAppToken: string = "";

		for (const numberWhatsAppToken of this._arrayNumberWhatsAppToken)
		{
			if (numberWhatsAppToken !== null && numberWhatsAppToken !== undefined)
			{
				stringWhatsAppToken += numberWhatsAppToken.toString();
			}
			else
			{

			}
		}
		return stringWhatsAppToken;
	}

	private getStringSMSTokenFromForm(): string
	{
		let stringSMSToken: string = "";

		for (const numberSMSToken of this._arrayNumberSMSToken)
		{
			if (numberSMSToken !== null && numberSMSToken !== undefined)
			{
				stringSMSToken += numberSMSToken.toString();
			}
			else
			{

			}
		}

		return stringSMSToken;
	}

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	//#endregion


	//#region LISTENER

	clickInputTokenWhatsApp(numberIndex: number): void
	{
		if (this._arrayElementInputWhatsAppToken != null)
		{
			this._arrayElementInputWhatsAppToken[numberIndex].focus();
			(this._arrayElementInputWhatsAppToken[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	clickInputTokenSMS(numberIndex: number): void
	{
		if (this._arrayElementInputSMSToken != null)
		{
			this._arrayElementInputSMSToken[numberIndex].focus();
			(this._arrayElementInputSMSToken[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	listenInputTokenWhatsApp(event: KeyboardEvent, numberIndex: number): void
	{
		if (this._arrayElementInputWhatsAppToken != null)
		{
			if (event.code === "Backspace")
			{
				if (numberIndex > 0)
				{
					this._arrayElementInputWhatsAppToken[numberIndex - 1].focus();
					(this._arrayElementInputWhatsAppToken[numberIndex - 1] as HTMLInputElement).value = "";
				}
				else
				{

				}
			}
			else
			{
				if (numberIndex < this._arrayElementInputWhatsAppToken.length - 1)
				{
					if (event.code === "Tab")
					{
						(this._arrayElementInputWhatsAppToken[numberIndex] as HTMLInputElement).value = "";
					}
					else
					{
						this._arrayElementInputWhatsAppToken[numberIndex + 1].focus();
						(this._arrayElementInputWhatsAppToken[numberIndex + 1] as HTMLInputElement).value = "";
					}
				}
				else
				{
					this.callVerifyCustomerByMobilePhone(this._stringWhatsApp);
				}
			}
		}
		else
		{

		}
	}

	listenInputTokenSMS(event: KeyboardEvent, numberIndex: number): void
	{
		if (this._arrayElementInputSMSToken != null)
		{
			if (event.code === "Backspace")
			{
				if (numberIndex > 0)
				{
					this._arrayElementInputSMSToken[numberIndex - 1].focus();
					(this._arrayElementInputSMSToken[numberIndex - 1] as HTMLInputElement).value = "";
				}
				else
				{

				}
			}
			else
			{
				if (numberIndex < this._arrayElementInputSMSToken.length - 1)
				{
					if (event.code === "Tab")
					{
						(this._arrayElementInputSMSToken[numberIndex] as HTMLInputElement).value = "";
					}
					else
					{
						this._arrayElementInputSMSToken[numberIndex + 1].focus();
						(this._arrayElementInputSMSToken[numberIndex + 1] as HTMLInputElement).value = "";
					}
				}
				else
				{
					this.callVerifyCustomerByMobilePhone(this._stringSMS);
				}
			}
		}
		else
		{

		}
	}

	//#endregion


	//#region FUNCTION

	getTokenFromURLParameter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];

		return stringToken;
	}

	setShowFormSMS()
	{
		this._booleanFormWhatsApp = true;
		this._booleanFormVerifyWhatsApp = true;
		this._booleanFormSMS = true;
		this._booleanFormVerifySMS = false;

		this.callUpdateCustomerByContactSMS(false);
	}

	setShowFormWhatsApp()
	{
		this._booleanFormWhatsApp = true;
		this._booleanFormVerifyWhatsApp = false;
		this._booleanFormSMS = true;
		this._booleanFormVerifySMS = true;

		this.callUpdateCustomerByContact(false);
	}

	//#endregion


	//#region FUNTION INTERVAL

	setTimerOneMinute(minute)
	{
		// let minute = 1;
		let numberSecond: number = minute * 60;
		let textSecond: any = "0";
		let numberStatusSecond: number = 60;

		const stringPrefix = minute < 10 ? "0" : "";

		this.timerInterval = setInterval(() =>
		{
			numberSecond--;
			if (numberStatusSecond !== 0)
			{
				numberStatusSecond--;
			}
			else
			{
				numberStatusSecond = 59;
			}

			if (numberStatusSecond < 10)
			{
				textSecond = "0" + numberStatusSecond;
			}
			else
			{
				textSecond = numberStatusSecond;
			}

			this.textTimer = `${stringPrefix}${Math.floor(numberSecond / 60)}:${textSecond}`;

			if (numberSecond === 0)
			{
				clearInterval(this.timerInterval);

				if(this._booleanButtonVerifyWhatsAppInterval === true)
				{
					this._booleanButtonVerifyWhatsAppInterval = false;
				}
				else if(this._booleanButtonVerifySMSInterval === true)
				{
					this._booleanButtonVerifySMSInterval = false;
				}
			}
		}, 1000);
	}

	startInterval()
	{
		this.setTimerOneMinute(1);
	}

	//#endregion


	//#region NAVIGATION

	goToHome(): void
	{
		this._router.navigate(["home"]);
	}

	//#endregion

}

//#endregion