<div id="divBodySearch">

    <div class="DivContentSearchContainer">
        <div class="DivContentSearch">
            <img src="../../assets/icons/sakina/icon_search.svg"  alt="Search">
            <div class="DivContentSearchDetailFilter">
                <input type="text" [(ngModel)]="_stringInputValue" (keyup)="searchByValue()" placeholder="Cari di disini" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)">
            </div>
        </div>

        <div class="DivContentResult" *ngIf="this._arrayModelContentTemporary.length > 0">
            <h2>Hasil Pencarian</h2>
            <div class="DivContentResultDetail" *ngFor="let modelContentSearch of this._arrayModelContentTemporary; let i = index; trackBy: trackSearch" (click)="goToSearch(modelContentSearch.Path2!, modelContentSearch.Path3!)">
                <div class="DivContentResultImage">
                    <img [src]="modelContentSearch.Path" alt="PT. BRI Insurance" >
                </div>
                <div class="DivContentResultDescription">
                    <h3>{{ modelContentSearch.Name }}</h3>
                    <p>{{ modelContentSearch.Description }}</p>
                </div>
            </div>
        </div>

        <div class="DivContentNotFound" *ngIf="this._arrayModelContentTemporary.length === 0 && (_stringInputValue !== undefined  && _stringInputValue !== '')">
            <img src="../../assets/icons/sakina/icon_search_notfound.svg">
            <h2>Hasil tidak ditemukan</h2>
            <p>Kami tidak dapat menemukan pencarian yang sesuai dari pencarian Anda</p>
        </div>

    </div>


</div>

<app-footer></app-footer>