//#region IMPORT

import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToDateMedium"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateMedium implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		const dateDate: Date = new Date(stringValue as string);

		let stringHours: string = "00";

		if (dateDate.getHours() == null || dateDate.getHours() === undefined || dateDate.getHours() === 0)
		{

		}
		else
		{
			stringHours = dateDate.getHours().toString();

			if (stringHours.length === 1)
			{
				stringHours = `0${stringHours}`;
			}
			else
			{

			}
		}

		let stringMinutes: string = "00";

		if (dateDate.getMinutes() == null || dateDate.getMinutes() === undefined || dateDate.getMinutes() === 0)
		{

		}
		else
		{
			stringMinutes = dateDate.getMinutes().toString();

			if (stringMinutes.length === 1)
			{
				stringMinutes = `0${stringMinutes}`;
			}
			else
			{

			}
		}

		let stringSeconds: string = "00";

		if (dateDate.getSeconds() == null || dateDate.getSeconds() === undefined || dateDate.getSeconds() === 0)
		{

		}
		else
		{
			stringSeconds = dateDate.getSeconds().toString();

			if (stringSeconds.length === 1)
			{
				stringSeconds = `0${stringSeconds}`;
			}
			else
			{

			}
		}

		const stringDateShort: string = new ConvertToDateShort().transform(dateDate) as string;

		return `${stringDateShort} | ${stringHours}:${stringMinutes}:${stringSeconds}`;
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToDateShort"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateShort implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			const dateDate: Date = new Date(stringValue as string);

			let stringMonth: string = (dateDate.getMonth() + 1).toString();

			if (stringMonth.length === 1)
			{
				stringMonth = `0${stringMonth}`;
			}
			else
			{

			}

			if (stringMonth === "01")
			{
				stringMonth = "Januari";
			}
			else if (stringMonth === "02")
			{
				stringMonth = "Februari";
			}
			else if (stringMonth === "03")
			{
				stringMonth = "Maret";
			}
			else if (stringMonth === "04")
			{
				stringMonth = "April";
			}
			else if (stringMonth === "05")
			{
				stringMonth = "Mei";
			}
			else if (stringMonth === "06")
			{
				stringMonth = "Juni";
			}
			else if (stringMonth === "07")
			{
				stringMonth = "Juli";
			}
			else if (stringMonth === "08")
			{
				stringMonth = "Agustus";
			}
			else if (stringMonth === "09")
			{
				stringMonth = "September";
			}
			else if (stringMonth === "10")
			{
				stringMonth = "Oktober";
			}
			else if (stringMonth === "11")
			{
				stringMonth = "November";
			}
			else
			{
				stringMonth = "Desember";
			}

			return `${dateDate.getDate()} ${stringMonth} ${dateDate.getFullYear()}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToDay"
	}
)

//#endregion


//#region CLASS

export class ConvertToDay implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			const dateDate: Date = new Date(stringValue as string);

			return `${dateDate.getDate()}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToMonth"
	}
)

//#endregion


//#region CLASS

export class ConvertToMonth implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			const dateDate: Date = new Date(stringValue as string);

			let stringMonth: string = (dateDate.getMonth() + 1).toString();

			if (stringMonth.length === 1)
			{
				stringMonth = `0${stringMonth}`;
			}
			else
			{

			}

			if (stringMonth === "01")
			{
				stringMonth = "Jan";
			}
			else if (stringMonth === "02")
			{
				stringMonth = "Feb";
			}
			else if (stringMonth === "03")
			{
				stringMonth = "Mar";
			}
			else if (stringMonth === "04")
			{
				stringMonth = "Apr";
			}
			else if (stringMonth === "05")
			{
				stringMonth = "Mei";
			}
			else if (stringMonth === "06")
			{
				stringMonth = "Jun";
			}
			else if (stringMonth === "07")
			{
				stringMonth = "Jul";
			}
			else if (stringMonth === "08")
			{
				stringMonth = "Agu";
			}
			else if (stringMonth === "09")
			{
				stringMonth = "Sep";
			}
			else if (stringMonth === "10")
			{
				stringMonth = "Okt";
			}
			else if (stringMonth === "11")
			{
				stringMonth = "Nov";
			}
			else
			{
				stringMonth = "Des";
			}

			return `${stringMonth}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToYear"
	}
)

//#endregion


//#region CLASS

export class ConvertToYear implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			const dateDate: Date = new Date(stringValue as string);


			return `${dateDate.getFullYear()}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "initiateWithoutUTC"
	}
)

//#endregion


//#region CLASS

export class InitiateWithoutUTC implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		if (value == null || value === undefined)
		{
			return "";
		}
		else
		{
			const dateDate: Date = new Date(value as string).initiateWithoutUTC();

			return dateDate;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertEmptyToDash"
	}
)

//#endregion


//#region CLASS

export class ConvertEmptyToDash implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertEmptyDateToDash"
	}
)

//#endregion


//#region CLASS

export class ConvertEmptyDateToDash implements PipeTransform
{
	//#region FUNCTION

	transform(dateValue?: Date): any
	{
		if (dateValue == null || dateValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			return dateValue.toString();
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertDashToGuest"
	}
)

//#endregion


//#region CLASS

export class ConvertDashToGuest implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "" || stringValue === "-")
		{
			return "Guest";
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertDashToGuestInitial"
	}
)

//#endregion


//#region CLASS

export class ConvertDashToGuestInitial implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "" || stringValue === "-")
		{
			return "G";
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion

//#region PIPE

@Pipe
(
	{
		name: "currencyValue"
	}
)

//#endregion


//#region CLASS

export class CurrencyValue implements PipeTransform
{
	//#region FUNCTION

	transform(numberValue?: any): number
	{
		if (numberValue == null || numberValue === undefined)
		{
			return 0;
		}
		else
		{
			numberValue = numberValue.toLocaleString(["ban", "id"]);

			return numberValue as number;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertNoDivisionToCustomer"
	}
)

//#endregion


//#region CLASS

export class ConvertNoDivisionToCustomer implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return "Customer";
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion

//#region PIPE

@Pipe
(
	{
		name: "convertSeparateEnumSpace"
	}
)

//#endregion


//#region CLASS

export class ConvertSeparateEnumSpace implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return stringValue as string;
		}
		else
		{
			stringValue = stringValue.replace( /([a-z])([A-Z])/g, "$1 $2" );
			stringValue = stringValue.charAt(0).toUpperCase() + stringValue.slice(1);

			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertByteForPreview"
	}
)

//#endregion


//#region CLASS

export class ConvertByteForPreview implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue != null && stringValue !== undefined && stringValue !== "")
		{
			const numberValue: number = parseInt(stringValue, 10);

			if (numberValue < 1000)
			{
				return numberValue.toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Bytes";
			}
			else if (numberValue < 1000000)
			{
				return (numberValue / 1000).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Kb";
			}
			else if (numberValue < 1000000000)
			{
				return (numberValue / 1000000).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Mb";
			}
			else
			{
				return (numberValue / 1000000).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Gb";
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY + " Bytes";
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertExtensionToIcon"
	}
)

//#endregion


//#region CLASS

export class ConvertExtensionToIcon implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_PDF)
		{
			return "../../assets/icons/sakina/icon_file_pdf.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_IMAGE_JPEG || stringValue === StringConstant.STRING_FORMAT_IMAGE_JPG || stringValue === StringConstant.STRING_FORMAT_IMAGE_PNG)
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_COMPRESS_RAR || stringValue === StringConstant.STRING_FORMAT_COMPRESS_ZIP)
		{
			return "../../assets/icons/sakina/icon_file_compress.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_EXCEL || stringValue === StringConstant.STRING_FORMAT_DOCUMENT_EXCELX)
		{
			return "../../assets/icons/sakina/icon_file_excel.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_CSV)
		{
			return "../../assets/icons/sakina/icon_file_csv.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_WORD || stringValue === StringConstant.STRING_FORMAT_DOCUMENT_WORDX)
		{
			return "../../assets/icons/sakina/icon_file_word.svg";
		}
		else
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "safe"
	}
)

//#endregion


//#region CLASS

export class SafePipe implements PipeTransform
{
	//#region FUNCTION
  	constructor(private _sanitizer: DomSanitizer) { }

	transform(stringValue: any): SafeResourceUrl
	{
		return this._sanitizer.bypassSecurityTrustResourceUrl(stringValue);
	}
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToTimeShort"
	}
)

//#endregion


//#region CLASS

export class ConvertToTimeShort implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		const dateDate: Date = new Date(stringValue as string);

		let stringHours: string = "00";

		if (dateDate.getHours() == null || dateDate.getHours() === undefined || dateDate.getHours() === 0)
		{

		}
		else
		{
			stringHours = dateDate.getHours().toString();

			if (stringHours.length === 1)
			{
				stringHours = `0${stringHours}`;
			}
			else
			{

			}
		}

		let stringMinutes: string = "00";

		if (dateDate.getMinutes() == null || dateDate.getMinutes() === undefined || dateDate.getMinutes() === 0)
		{

		}
		else
		{
			stringMinutes = dateDate.getMinutes().toString();

			if (stringMinutes.length === 1)
			{
				stringMinutes = `0${stringMinutes}`;
			}
			else
			{

			}
		}

		return `${stringHours}:${stringMinutes}`;
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToDateFull"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateFull implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			const dateDate: Date = new Date(stringValue as string);

			let stringDay: string = dateDate.getDay().toString();
			let stringMonth: string = (dateDate.getMonth() + 1).toString();

			if(stringDay === "1")
			{
				stringDay = "Senin";
			}
			else if(stringDay === "2")
			{
				stringDay = "Selasa";
			}
			else if(stringDay === "3")
			{
				stringDay = "Rabu";
			}
			else if(stringDay === "4")
			{
				stringDay = "Kamis";
			}
			else if(stringDay === "5")
			{
				stringDay = "Jum'at";
			}
			else if(stringDay === "6")
			{
				stringDay = "Sabtu";
			}
			else
			{
				stringDay = "Minggu";
			}

			if (stringMonth.length === 1)
			{
				stringMonth = `0${stringMonth}`;
			}
			else
			{

			}

			if (stringMonth === "01")
			{
				stringMonth = "Januari";
			}
			else if (stringMonth === "02")
			{
				stringMonth = "Februari";
			}
			else if (stringMonth === "03")
			{
				stringMonth = "Maret";
			}
			else if (stringMonth === "04")
			{
				stringMonth = "April";
			}
			else if (stringMonth === "05")
			{
				stringMonth = "Mei";
			}
			else if (stringMonth === "06")
			{
				stringMonth = "Juni";
			}
			else if (stringMonth === "07")
			{
				stringMonth = "Juli";
			}
			else if (stringMonth === "08")
			{
				stringMonth = "Agustus";
			}
			else if (stringMonth === "09")
			{
				stringMonth = "September";
			}
			else if (stringMonth === "10")
			{
				stringMonth = "Oktober";
			}
			else if (stringMonth === "11")
			{
				stringMonth = "November";
			}
			else
			{
				stringMonth = "Desember";
			}

			return `${stringDay}, ${dateDate.getDate()} ${stringMonth} ${dateDate.getFullYear()}`;
		}
	}

	//#endregion
}

//#endregion



