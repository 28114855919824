<div id="divMainTermsAndCondition">
    <div class="DivHeaderPolicy">
        <img id="imgHeaderShape" src="../assets/shapes/sakina/shape_faq_friend.svg" >
		<img id="imgHeaderResponsive" src="../assets/shapes/sakina/shape_faq_friend_mobile_responsive.svg" >
        <h1>Syarat dan Ketentuan</h1>
    </div>

    <div class="DivSiteContainer DivMainPolicy">
        <section>
            <h3>ISTILAH</h3>
            <p>Website <a (click)="goToLink('www.brins.co.id')">www.brins.co.id</a> adalah saluran distribusi PT. BRI Asuransi Indonesia untuk memberikan informasi-informasi terkait produk serta perusahaan melalui jaringan internet dengan menggunakan perangkat lunak browser pada komputer maupun ponsel.</p>

            <h3>KETENTUAN PENGGUNAAN <strong (click)="goToLink('www.brins.co.id')">WWW.BRINS.CO.ID</strong></h3>
            <ol class="OrderedNumber">
                <li>
                    <p>
                        Pengguna dapat menggunakan layanan website <a (click)="goToLink('www.brins.co.id')">www.brins.co.id</a> untuk mendapatkan informasi.
                    </p>
                </li>
                <li>
                    <p>
                        Setiap pertukaran data informasi telah disetujui dari Pengguna yang tersimpan pada pusat data BRINS merupakan data yang benar yang diterima sebagai bukti dari Pengguna kepada BRINS untuk melaksanakan pertukaran data informasi yang dimaksud.
                    </p>
                </li>
                <li>
                    <p>
                        BRINS berhak menghentikan layanan <a (click)="goToLink('www.brins.co.id')">www.brins.co.id</a> untuk sementara waktu maupun untuk jangka waktu tertentu yang ditentukan oleh BRINS untuk keperluan pembaharuan, pemeliharaan atau untuk tujuan lain dengan alasan apapun yang dianggap baik oleh BRINS, dan untuk itu BRINS tidak berkewajiban mempertanggungjawabkannya kepada siapapun.
                    </p>
                </li>
                <li>
                    <p>
                        Penggunaan email terdaftar :
                    </p>
                    <ol class="OrderedNumber" type="a" >
                        <li>
                            <p>
                                Alamat email yang didaftarkan oleh Pengguna merupakan email yang akan digunakan oleh BRINS untuk mengirim informasi yang telah dilakukan oleh Pengguna melalui www.brins.co.id.
                            </p>
                        </li>
                        <li>
                            <p>
                                BRINS hanya mengirimkan informasi kepada alamat email yang telah dikonfirmasikan kebenarannya oleh Pengguna kepada BRINS dan BRINS tidak bertanggung jawab atas kebenaran alamat email tersebut.
                            </p>
                        </li>
                        <li>
                            <p>
                                BRINS tidak menjamin keamanan informasi atau data yang dikirim oleh Pengguna kepada BRINS melalui email yang tidak terdapat di <a (click)="goToLink('www.brins.co.id')">www.brins.co.id</a>, yang tidak dalam format yang aman yang disetujui atau ditentukan oleh BRINS.
                            </p>
                        </li>
                    </ol>
                </li>
            </ol>
            <h3>
                FORCE MAJEURE
            </h3>
            <p>
                Pengguna akan membebaskan BRINS dari segala tuntutan apapun, dalam hal BRINS tidak dapat melaksanakan perintah dari Pengguna baik sebagian maupun seluruhnya karena kejadian-kejadian atau sebab-sebab di luar kekuasaan atau kemampuan BRINS termasuk namun tidak terbatas pada segala gangguan virus komputer atau komponen membahayakan yang dapat menggangu layanan www.brins.co.id, web browser atau komputer sistem BRINS, Pengguna, atau Internet Service Provider, karena bencana alam, perang, huru-hara, keadaan peralatan, sistem atau transmisi yang tidak berfungsi, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, serta kejadian-kejadian atau sebab-sebab lain di luar kekuasaan atau kemampuan BRINS.
            </p>
            <h3>LAIN LAIN</h3>
            <ol class="OrderedNumber">
                <li>
                    <p>
                        Pengguna dapat menghubungi Contact Center BRINS atas setiap permasalahan yang berkaitan dengan akses layanan <a (click)="goToLink('www.brins.co.id')">www.brins.co.id</a>
                    </p>
                </li>
                <li>
                    <p>
                        BRINS dapat mengubah syarat dan ketentuan ini setiap saat dengan pemberitahuan terlebih dahulu kepada Pengguna dalam bentuk tertulis dilayar <a (click)="goToLink('www.brins.co.id')">www.brins.co.id</a> atau berupa email ataupun melalui sarana lainnya.
                    </p>
                </li>
                <li>
                    <p>
                        Pengguna tunduk pada ketentuan-ketentuan dan peraturan-peraturan yang berlaku pada BRINS termasuk setiap perubahan yang akan diberitahukan terlebih dahulu oleh BRINS dalam bentuk dan sarana apapun.
                    </p>
                </li>
            </ol>
        </section>
    </div>
</div>

<app-footer></app-footer>