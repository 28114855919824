//#region CLASS

import { ENUM_FAQ_TYPE } from "../constants/enum.constant";

export class ContentModel
{
	/*
		Attribute - START
		Description : Attribute for country model, used for location and link to product and other models.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.		    	Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	Code?: string;
	Name?: string;
	Path?: string;
	Path2?: string;
	Path3?: string;
	Title?: string;
	Description?: string;
	Description2?: string;
	Description3?: string;
	Paragraph?: string;
	Detail?: string;
	Date?: Date;
	NowDate?: string;
	NowMonth?: string;
	NowYear?: string;
	Year?: number;
	Link?: string;
	Type?: ENUM_FAQ_TYPE;

	/* Attribute - END */
}

//#endregion