//#region CLASS

export class RuleConstant
{
	//#region FORM

	static NUMBER_EMAILTOKEN_LENGTH: number = 6;
	static NUMBER_EMAILTOKENSINGLE_LENGTH: number = 1;
	static NUMBER_SEARCH_LENGTHMINIMUM: number = 3;
	static NUMBER_POSTALCODE_LENGTH: number = 5;
	static NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH: number = 16;
	static NUMBER_YEAR_LENGTH: number = 4;
	static NUMBER_AGE_VALUEMINIMUM: number = 17;
	static NUMBER_FIRSTNAME_LENGTHMINIMUM: number = 3;
	static NUMBER_FIRSTNAME_LENGTHMAXIMUM: number = 15;
	static NUMBER_NAME_MAXIMUM: number = 40;
	static NUMBER_MOBILEPHONE_LENGTHMINIMUM: number = 9;
	static NUMBER_MOBILEPHONE_LENGTHMAXIMUM: number = 13;
	static NUMBER_TAXNUMBER_LENGTH: number = 15;
	static NUMBER_DASHBOARDDATEPERIOD_RANGE: number = 6;
	static NUMBER_POLICY_NUMBER_LENGTH: number = 16;
	static NUMBER_REFERENCE_NUMBER_LENGTH: number = 16;
	static NUMBER_POLICY_NUMBER_LENGTH_TWENTY: number = 20;
	static NUMBER_ENGINE_NUMBER_LENGTHMINIMUM: number = 10;
	static NUMBER_ENGINE_NUMBER_LENGTHMAXIMUM: number = 16;
	static NUMBER_CHASIS_NUMBER_LENGTHMINIMUM: number = 15;
	static NUMBER_CHASIS_NUMBER_LENGTHMAXIMUM: number = 20;

	//#endregion


	//#region INCIDENT

	static NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMINIMUM: number = 10;
	static NUMBER_CLAIMPROPOSAL_INCIDENTCHRONOLOGY_LENGTHMAXIMUM: number = 50;

	//#endregion


	//#region VEHICLE

	static NUMBER_VEHICLESPEED_VALUEMAXIMUM: number = 250;
	static NUMBER_VEHICLESPEED_VALUEMINIMUM: number = 1;

	//#region


	//#region DRIVER

	static NUMBER_DRIVERLICENSENUMBER_NUMBERMAXIMUM: number = 16;
	static NUMBER_DRIVERLICENSENUMBER_NUMBERMINIMUM: number = 12;

	//#endregion


	//#region PRODUCT

	static NUMBER_BUILDINGPRICE_VALUEMINIMUM: number = 1000000.0;
	static NUMBER_INTERIORPRICE_VALUEMINIMUM: number = 100000.0;
	static NUMBER_BUILDINGADDRESS_LENGTHMINIMUM: number = 10;
	static NUMBER_REFERENCENUMBER_LENGTH: number = 18;

	//#endregion


	//#region UPLOAD

	static NUMBER_FILE_COMPRESS_SIZEMAXIMUM_FOR_TICKET: number = 5000000.0;
	static NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM_FOR_TICKET: number = 5000000.0;
	static NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_TICKET: number = 40000000.0;

	static NUMBER_FILE_COMPRESS_SIZEMAXIMUM: number = 50000000.0;
	static NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM: number = 50000000.0;
	static NUMBER_FILE_TOTAL_SIZEMAXIMUM: number = 100000000.0;

	static NUMBER_FILE_COMPRESS_SIZEMAXIMUM_FOR_CLAIMPROPOSAL: number = 50000000.0;
	static NUMBER_FILE_NONCOMPRESS_SIZEMAXIMUM_FOR_CLAIMPROPOSAL: number = 50000000.0;
	static NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL_SUPPORTINGDOCUMENT: number = 5500000.0;
	static NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_CLAIMPROPOSAL: number = 20000000.0;
	static NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_ENDORSEMENT: number = 12000000.0;
	static NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_OUTLOOKARTICLE: number = 10000000.0;


	static STRING_FILE_TYPE_ALLOWED: string = "image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv";
	static STRING_FILE_TYPE_ALLOWED_ONLY_EXCEL: string = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv";
	static STRING_FILE_TYPE_ALLOWED_ONLY_DOCUMENT: string = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv";

	//#endregion
}

//#endregion