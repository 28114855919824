//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { RegexFunction } from "src/app/functions/regex.function";
import { ContentModel } from "src/app/models/content.model";
import { SessionService } from "src/app/services/session.service";
import { environment } from "src/environments/environment";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-joinus",
		templateUrl: "./joinus.component.html",
		styleUrls: ["./joinus.component.sass"],
	}
)
export class JoinUsComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _enumStringConstant = StringConstant;
	public _stringConstant = StringConstant;
	public _stringPrefixCode: string;
	public _modelJoinUsCareers: ContentModel;
	public _modelJoinUsPartnership: ContentModel;
	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._stringPrefixCode = "+62";
		this._functionRegex = new RegexFunction();
		this._modelJoinUsCareers =
		{
			ID: 0,
			Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/joinus_career.jpg",
			Name: "Careers",
			Description: "Jadilah #GenerasiBRILian dan mulai melangkah kembangkan karirmu bersama BRINS."
		};
		this._modelJoinUsPartnership =
		{
			ID: 0,
			Path: environment.URL_BROADCAST_API+"/Website/assets/background/sakina/joinus_partnership.jpg",
			Name: "Partnership",
			Description: "Jadilah #GameChanger bersama BRINS, berikan perlindungan terbaik untuk Indonesia."
		};
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	trackMilestone(index, modelMilestone: ContentModel)
	{
		return modelMilestone ? modelMilestone.ID : undefined;
	}

	//#endregion


	//#region NAVIGATION

	//#endregion

}
