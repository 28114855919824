//#region IMPORT

import { Component, OnInit } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-redirecttoapps",
		templateUrl: "./redirecttoapps.component.html",
		styleUrls: ["./redirecttoapps.component.sass"]
	}
)

//#endregion


//#region CLASS

export class RedirectToAppsComponent implements OnInit
{

	//#region DECLARATION

	//#endregion


	//#region CONSTRUCTOR

	constructor() { }

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		window.location.href = StringConstant.STRING_REDIRECT_BRINSMOBILE_APPS;
	}

	//#endregion
}

//#endregion
