//#region IMPORT

import { ENUM_DEVICE_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { FrequentlyAskedQuestionsQuestionModel } from "./frequentlyaskedquestionsquestion.model";
import { FrequentlyAskedQuestionsQuestionTypeModel } from "./frequentlyaskedquestionsquestiontype.model";

//#endregion


//#region CLASS

export class FrequentlyAskedQuestionsQuestionSubtypeModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for frequently asked questions question sub type model, used for BRINS' Website FAQ.
		Author: Billy Johannes.
		Created on : Tuesday, 12 April 2022. 			Updated on : Saturday, 18 March 2023.
		Created by : Billy Johannes.					Updated by : Dimas Dandy Aryarajendra Suprapto.
		Version : 1.0:0.
	*/

	ID?: number;
	Token?: string;

	FrequentlyAskedQuestionsQuestionTypeID?: number;

	Priority?: number;
	Name?: string;

	FrequentlyAskedQuestionsQuestionTypeName?: string;
	FrequentlyAskedQuestionsQuestionTypeDeviceType?: ENUM_DEVICE_TYPE;
	FrequentlyAskedQuestionsQuestionTypeCulture?: string;

	modelFrequentlyAskedQuestionsQuestionType?: FrequentlyAskedQuestionsQuestionTypeModel;
	listModelFrequentlyAskedQuestionsQuestion?: Array<FrequentlyAskedQuestionsQuestionModel>;

	/* Attribute - END */

	/* Constructor - START */

	constructor()
	{
		super();

		this.modelFrequentlyAskedQuestionsQuestionType = new FrequentlyAskedQuestionsQuestionTypeModel();
		this.listModelFrequentlyAskedQuestionsQuestion = [];
	}

	/* Constructor - END */
}

//#endregion