<div id="divBodyAbout" class="DivBodyContainer">
	<div class="DivContentMain">
		<div class="DivContentAbout">
			<h1>Join</h1><h1> Us</h1>
			<div class="DivContentTextDescription">
				<p>Deskripsi bahwa Join Us merupakan halaman untuk user yang ingin mendaftar bergabung Bersama BRINS. Kategori untuk mendaftar.</p>
				<p>1. Career</p>
				<p>2. Partnership</p>
				<p>3. BRINS API</p>
			</div>
		</div>
		<div class="DivContentImage">
			<figure>
				<img src="https://development.broadcast.api.brinesia.app/Website/assets/background/sakina/background_economic_value.png">
				<img src="../../assets/icons/sakina/patternheader/icon_2.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_3.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_4.svg">
				<img src="../../assets/icons/sakina/patternheader/icon_1.svg">
			</figure>
		</div>
	</div>
</div>

<div id="divContentJoinUsCareers">
	<img [src]="_modelJoinUsCareers.Path">
	<div class="DivContent">
		<div class="DivContentDescription">
			<h2>{{ _modelJoinUsCareers.Name }}</h2>

			<div class="DivContentTextDescription">
				<p>{{ _modelJoinUsCareers.Description }}</p>
			</div>

			<div class="DivContentButtonAbout">
				<div class="DivContentButtonDetail">
					<a class="ButtonForward">
						<span>See Detail</span>
						<svg width="13px" height="10px" viewBox="0 0 13 10">
							<path d="M1,5 L11,5"></path>
							<polyline points="8 1 12 5 8 9"></polyline>
						</svg>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<div id="divContentJoinUsPartnership" [ngStyle]="{'background-image': 'url(' + _modelJoinUsPartnership.Path + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover'}">
	<div class="DivContent">
		<div class="DivContentDescription">
			<h2>{{ _modelJoinUsPartnership.Name }}</h2>

			<div class="DivContentTextDescription">
				<p>{{ _modelJoinUsPartnership.Description }}</p>
			</div>
		</div>
	</div>

	<div class="DivContentForm">
		<form>
			<div class="DivForm">
				<fieldset>
					<div class="DivFormHorizontalContainer">
						<label for="textCompanyName">Company Name*</label>
						<input type="text" name="modelPartnershipCompanyName" id="textCompanyName" placeholder="input your company name" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">

						<label for="textPhoneNumber">Phone Number*</label>
						<div class="DivFormHorizontalContainer">
							<input type="text" name="modelPartnershipPhoneNumberPrefix" id="textPhoneNumberPrefix" list="phoneNumberPrefix" [ngModel]="_stringPrefixCode">
							<input type="text" name="modelPartnershipPhoneNumber" id="textPhoneNumber" placeholder="input your phone number" (keypress)="this._functionRegex.regexNumber($event)">
							<datalist id="phoneNumberPrefix">
								<option [value]="numberCountryCode" *ngFor="let numberCountryCode of _stringConstant.ARRAY_VALUE_COUNTRYCODE">
							</datalist>
						</div>

						<label for="textEmail">Email*</label>
						<input type="text" name="modelPartnershipEmail" id="textEmail" placeholder="input input your email" (keypress)="this._functionRegex.regexEmail($event)">

						<label for="textareaMessages">Messages*</label>
						<textarea type="text" name="modelPartnershipMessages" id="textareaMessages" placeholder="input messages" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>
					</div>

					<div class="DivContentButtonAbout">
						<div class="DivContentButtonDetail">
							<a class="ButtonForward">
								<span>Submit</span>
								<svg width="13px" height="10px" viewBox="0 0 13 10">
									<path d="M1,5 L11,5"></path>
									<polyline points="8 1 12 5 8 9"></polyline>
								</svg>
							</a>
						</div>
					</div>
				</fieldset>
			</div>
		</form>
	</div>

</div>

<div id="divContentBRINSAPI" class="DivBodyContainer">
	<div class="DivContent2Column">
		<div class="DivContent">
			<h2>Increase the Flexibility of Your Business Service With BRINS API</h2>
			<p>
				Deskripsi bahwa BRINS saat ini telah memiliki API yang bisa diintegrasikan, lalu ajakan untuk menjangkau nasabah BRINS yang tersebar di seluruh Indonesia.
			</p>

			<div class="DivContentButtonAbout">
				<div class="DivContentButtonDetail">
					<a class="ButtonForward">
						<span>Register Now</span>
						<svg width="13px" height="10px" viewBox="0 0 13 10">
							<path d="M1,5 L11,5"></path>
							<polyline points="8 1 12 5 8 9"></polyline>
						</svg>
					</a>
				</div>
			</div>
		</div>
		<div class="DivContentImage">
			<img src="https://development.broadcast.api.brinesia.app/Website/assets/background/sakina/background_api.png">
		</div>
	</div>
</div>

<app-footer></app-footer>