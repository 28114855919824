//#region IMPORT

import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMap, GoogleMapsModule } from "@angular/google-maps";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { CKEditorModule } from "ng2-ckeditor";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { QuicklinkModule } from "ngx-quicklink";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AboutAwardComponent } from "./components/about/aboutaward/aboutaward.component";
import { AboutAwardArticleComponent } from "./components/about/aboutaward/aboutawardarticle/aboutawardarticle.component";
import { AboutBRINSComponent } from "./components/about/aboutbrins/aboutbrins.component";
import { AboutCompanyProfileComponent } from "./components/about/aboutcompanyprofile/aboutcompanyprofile.component";
import { AboutContactandLocationComponent } from "./components/about/aboutcontactandlocation/aboutcontactandlocation.component";
import { AboutCorporateValuesComponent } from "./components/about/aboutcorporatevalues/aboutcorporatevalues.component";
import { AboutOrganizationAndStructureComponent } from "./components/about/aboutorganizationandstructure/aboutorganizationandstructure.component";
import { AboutPartnerAndClientComponent } from "./components/about/aboutpartnerandclient/aboutpartnerandclient.component";
import { CheckDevToolsDirective } from "./components/bases/basecheckdevtools.directive";
import { BrinsUpdateComponent } from "./components/brinsupdate/brinsupdate/brinsupdate.component";
import { BrinsUpdateArticleComponent } from "./components/brinsupdate/brinsupdatearticle/brinsupdatearticle/brinsupdatearticle.component";
import { BrinsUpdateArticleEditorialComponent } from "./components/brinsupdate/brinsupdatearticle/brinsupdatearticleeditorial/brinsupdatearticleeditorial.component";
import { BrinsUpdateArticleFinancialLiteracyComponent } from "./components/brinsupdate/brinsupdatearticle/brinsupdatearticlefinancialliteracy/brinsupdatearticlefinancialliteracy.component";
import { BrinsUpdateArticleNewsFeedComponent } from "./components/brinsupdate/brinsupdatearticle/brinsupdatearticlenewsfeed/brinsupdatearticlenewsfeed.component";
import { BrinsUpdateCommunityComponent } from "./components/brinsupdate/brinsupdatecommunity/brinsupdatecommunity.component";
import { BrinsUpdateEditorialComponent } from "./components/brinsupdate/brinsupdateeditorial/brinsupdateeditorial.component";
import { BrinsUpdateFinancialLiteracyComponent } from "./components/brinsupdate/brinsupdatefinancialliteracy/brinsupdatefinancialliteracy.component";
import { BrinsUpdateNewsFeedsComponent } from "./components/brinsupdate/brinsupdatenewsfeeds/brinsupdatenewsfeeds.component";
import { BrinsUpdateTestimonyComponent } from "./components/brinsupdate/brinsupdatetestimony/brinsupdatetestimony.component";
import { CorouselComponent } from "./components/corousel/corousel.component";
import { HistoryClaimComponent } from "./components/customercare/claim/historyclaim/historyclaim.component";
import { SubmissionClaimComponent } from "./components/customercare/claim/submissionclaim/submissionclaim.component";
import { SurveysatisfactionComponent } from "./components/customercare/claim/surveysatisfaction/surveysatisfaction.component";
import { TrackingclaimComponent } from "./components/customercare/claim/trackingclaim/trackingclaim.component";
import { CustomerCareComponent } from "./components/customercare/customercare/customercare.component";
import { CustomerCareWorkshopComponent } from "./components/customercare/customercareworkshop/customercareworkshop.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DownloadComponent } from "./components/download/download.component";
import { EconomicValueComponent } from "./components/economicvalue/economicvalue.component";
import { EditemailComponent } from "./components/editemail/editemail.component";
import { EditmobilephoneComponent } from "./components/editmobilephone/editmobilephone.component";
import { EditprofileComponent } from "./components/editprofile/editprofile.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FrequentyAndQuestionsComponent } from "./components/frequentyandquestions/frequentyandquestions.component";
import { HomeComponent } from "./components/home/home.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { TableControlIncludeMiniComponent } from "./components/includes/tablecontrolmini.include/tablecontrolmini.include.component";
import { TableControlIncludePageComponent } from "./components/includes/tablecontrolpage.include/tablecontrolpage.include.component";
import { JoinUsComponent } from "./components/joinus/joinus.component";
import { LandingComponent } from "./components/landing/landing.component";
import { MenuComponent } from "./components/menu/menu.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { PrivacyPolicyComponent } from "./components/privacypolicy/privacypolicy.component";
import { RedirectToAppsComponent } from "./components/redirect/redirecttoapps/redirecttoapps.component";
import { AppComponent } from "./components/root/app.component";
import { SearchComponent } from "./components/search/search.component";
import { SigninComponent } from "./components/signin/signin.component";
import { SigninticketComponent } from "./components/signinticket/signinticket.component";
import { SignupComponent } from "./components/signup/signup.component";
import { SocialValueComponent } from "./components/socialvalue/socialvalue.component";
import { TermsAndConditionComponent } from "./components/termsandcondition/termsandcondition.component";
import { DeclarationverifyComponent } from "./components/verifiy/declarationverify/declarationverify.component";
import { NotificationrenewalComponent } from "./components/verifiy/notificationrenewal/notificationrenewal.component";
import { SubmissionverifyComponent } from "./components/verifiy/submissionverify/submissionverify.component";
import { UserverifyComponent } from "./components/verifiy/userverify/userverify.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { PwaService } from "./services/pwa.service";
import { EpolicyVerifyComponent } from "./components/verifiy/epolicyverify/epolicyverify.component";
import { TicketComponent } from "./components/customercare/ticket/ticket.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();
const maskConfig: Partial<IConfig> = {validation: false,};

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninticketComponent,
			HomeComponent,
			DashboardComponent,
			TableControlIncludeComponent,
			TableControlIncludeMiniComponent,
			TableControlIncludePageComponent,
			MenuComponent,
			CorouselComponent,
			HomeComponent,
			TicketComponent,
			LandingComponent,
			FooterComponent,
			AboutBRINSComponent,
			AboutPartnerAndClientComponent,
			AboutOrganizationAndStructureComponent,
			AboutCorporateValuesComponent,
			AboutCompanyProfileComponent,
			AboutAwardComponent,
			AboutContactandLocationComponent,
			EconomicValueComponent,
			JoinUsComponent,
			SocialValueComponent,
			CustomerCareComponent,
			CustomerCareWorkshopComponent,
			SearchComponent,
			BrinsUpdateComponent,
			BrinsUpdateCommunityComponent,
			BrinsUpdateNewsFeedsComponent,
			BrinsUpdateTestimonyComponent,
			BrinsUpdateFinancialLiteracyComponent,
			SubmissionClaimComponent,
			TermsAndConditionComponent,
			PrivacyPolicyComponent,
			FrequentyAndQuestionsComponent,
			BrinsUpdateArticleNewsFeedComponent,
			BrinsUpdateArticleFinancialLiteracyComponent,
			BrinsUpdateArticleComponent,
			AboutAwardArticleComponent,
			HistoryClaimComponent,
			DownloadComponent,
			UserverifyComponent,
			DeclarationverifyComponent,
			SubmissionverifyComponent,
			EditprofileComponent,
			SignupComponent,
			SigninComponent,
			EditemailComponent,
			EditmobilephoneComponent,
			NotificationComponent,
			RedirectToAppsComponent,
			NotificationrenewalComponent,
			TrackingclaimComponent,
			SurveysatisfactionComponent,
			CheckDevToolsDirective,
			BrinsUpdateEditorialComponent,
			BrinsUpdateArticleEditorialComponent,
			EpolicyVerifyComponent
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			NgxChartsModule,
			MatNativeDateModule,
			MatInputModule,
			MatSliderModule,
			MatDatepickerModule,
			ReactiveFormsModule,
			GoogleMapsModule,
			NgSelectModule,
			QuicklinkModule,
			NgxMaskDirective,
			NgxMaskPipe,
			CKEditorModule,
			ServiceWorkerModule.register("ngsw-worker.js",
				{
					enabled: environment.serviceWorker,
					// Register the ServiceWorker as soon as the app is stable
					// or after 30 seconds (whichever comes first).
					registrationStrategy: "registerWhenStable:30000"
				}
			),
		],
		providers:
		[
			GoogleMap,
			provideEnvironmentNgxMask(maskConfig),
			QuicklinkModule,
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			}
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion
