//#region IMPORT

import { ENUM_DEVICE_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { FrequentlyAskedQuestionsQuestionModel } from "./frequentlyaskedquestionsquestion.model";
import { FrequentlyAskedQuestionsQuestionSubtypeModel } from "./frequentlyaskedquestionsquestionsubtype.model";

//#endregion


//#region CLASS

export class FrequentlyAskedQuestionsQuestionTypeModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for frequently asked questions question type model, used for BRINS' Website FAQ.
		Author: Billy Johannes.
		Created on : Tuesday, 12 April 2022. 			Updated on : Tuesday, 12 April 2022.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

	ID?: number;
	Token?: string;

	Code?: string;
	Name?: string;
	Description?: string;

	ClientDeviceType?: ENUM_DEVICE_TYPE;
	Culture?: string;

	listModelFrequentlyAskedQuestionsQuestion?: Array<FrequentlyAskedQuestionsQuestionModel>;
	listModelFrequentlyAskedQuestionsQuestionSubtype?: Array<FrequentlyAskedQuestionsQuestionSubtypeModel>;

	/* Attribute - END */

	/* Constructor - START */

	constructor()
	{
		super();
		this.listModelFrequentlyAskedQuestionsQuestion = [];
		this.listModelFrequentlyAskedQuestionsQuestionSubtype = [];
	}

	/* Constructor - END */

}

//#endregion