//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { HeaderModel } from "../models/header.model";
import { LandingHomepageModel } from "../models/landinghomepage.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class HeaderService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SERVICE

	selectHeaderWithSubHeader(interfaceGeneralService: GeneralServiceInterface, modelHeader: HeaderModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelHeader), WebAddressConstant.STRING_URL_HEADER_SELECTHEADERWITHSUBHEADER);
	}

	selectHeaderByHeaderType(interfaceGeneralService: GeneralServiceInterface, modelHeader: HeaderModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelHeader), WebAddressConstant.STRING_URL_HEADER_SELECTHEADERBYHEADERTYPE);
	}

	selectLandingHomepage(interfaceGeneralService: GeneralServiceInterface, modelHeader: LandingHomepageModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelHeader), WebAddressConstant.STRING_URL_LANDINGHOMEPAGE_SELECTLANDINGHOMEPAGE);
	}

	//#endregion

}

//#endregion
