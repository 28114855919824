//#region IMPORT

import { ENUM_DIVISION_ID, ENUM_RESPONSE_STATE, ENUM_TICKETCHAT_TYPE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";
import { TicketDocumentModel } from "./ticketdocument.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class TicketConversationModel extends BaseModel
{
	ID?: number;
	TicketID?: number;
	UserID?: number;
	Token?: string;

	Title?: string;
	Content?: string;

	Type?: ENUM_TICKETCHAT_TYPE;

	modelUser?: UserModel;
	listModelTicketConversationDocument?: Array<TicketDocumentModel>;

	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelUser = new UserModel();
		this.Type = ENUM_TICKETCHAT_TYPE.ChatPrivacy;
		this.listModelTicketConversationDocument = [];
	}

	//#endregion


	//#region VALIDATION

	validateTicketConversation(modelUserSignIn: UserModel | null): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ticket Conversation");

		if (this.Content === undefined || this.Content == null || this.Content === "")
		{
			modelResponse.MessageContent = "Content can't be empty, please fill in the blanks!";
		}
		else if (modelUserSignIn != null || modelUserSignIn !== undefined)
		{
			if(modelUserSignIn?.modelDivision?.ID === ENUM_DIVISION_ID.OperationAndSupport || modelUserSignIn?.DivisionID === ENUM_DIVISION_ID.OperationAndSupport)
			{
				if(this.Type === undefined || this.Type == null || this.Type === ENUM_TICKETCHAT_TYPE.ChatPrivacy)
				{
					modelResponse.MessageContent = "Chat privacy can't be empty please select one!";
				}
				else
				{
					modelResponse.MessageContent = "Ticket conversation is filled corectly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else
			{
				modelResponse.MessageContent = "Ticket conversation is filled corectly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
			modelResponse.MessageContent = "Ticket conversation is filled corectly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelTicketDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ticket Conversation");

		if(this.listModelTicketConversationDocument == null || this.listModelTicketConversationDocument === undefined)
		{
			modelResponse.MessageContent = "Error occured on attachment list, list is empty.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddModelTicketDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelTicketConversationDocument?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{

			}
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseTicketDocument: ResponseModel;
			let numberSize: number = 0;

			for (const modelTicketDocument of this.listModelTicketConversationDocument ?? [])
			{
				modelResponseTicketDocument = modelTicketDocument.validateAdd();

				if (modelResponseTicketDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseTicketDocument;
				}
				else
				{
					numberSize += modelTicketDocument.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_TICKET)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM_FOR_TICKET);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	getTokenForUpdate(stringToken: string | undefined): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Ticket Conversation");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token can't be empty.";
		}
		else
		{
			this.Token = stringToken;
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion