<div id="divBodySignIn" appCheckDevTools>
    <header id="headerSignIn">
        <h1>Customer Care</h1>

        <span id="spanSubTitle">E-Ticket</span>
    </header>

    <form>
        <div class="DivForm TextCenter">
            <h3>Lampiran Anda akan di unduh sebentar lagi.</h3>
        </div>
        <input type="button" value="Coba lagi" (click)="callDownload();">
        <input type="button" value="Kembali ke Halaman Sebelumnya" (click)="callBackPage();">
        <input type="button" value="Kembali ke Halaman Utama" (click)="getLocation();">
    </form>
</div>