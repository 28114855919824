//#region IMPORT

import { Pipe, PipeTransform } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "trimShort"
	}
)

//#endregion


//#region CLASS

export class TrimShort implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			if (stringValue.length <= 32)
			{
				return stringValue;
			}
			else
			{
				return stringValue.substring(0, 32) + "...";
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "trimMedium"
	}
)

//#endregion


//#region CLASS

export class TrimMedium implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			if (stringValue.length <= 64)
			{
				return stringValue;
			}
			else
			{
				return stringValue.substring(0, 64) + "...";
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "trimLarge"
	}
)

//#endregion


//#region CLASS

export class TrimLarge implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			if (stringValue.length <= 100)
			{
				return stringValue;
			}
			else
			{
				return stringValue.substring(0, 100) + "...";
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "trimLong"
	}
)

//#endregion


//#region CLASS

export class TrimLong implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			if (stringValue.length <= 150)
			{
				return stringValue;
			}
			else
			{
				return stringValue.substring(0, 150) + "...";
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "trimContent"
	}
)

//#endregion


//#region CLASS

export class TrimContent implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue == null || stringValue === undefined)
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			if (stringValue.length <= 300)
			{
				return stringValue;
			}
			else
			{
				return stringValue.substring(0, 300) + "...";
			}
		}
	}

	//#endregion
}

//#endregion