//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { CustomerModel } from "src/app/models/customer.model";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _modelUserSignIn: UserModel;
	public _modelCustomerSignIn: CustomerModel;

	latitude: number = -1.605328;
	longitude: number = 117.451067;
	type = "SATELLITE";
	zoom: number = 15;
	minzoom: number = 2;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();
		this._modelCustomerSignIn = new CustomerModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", this._stringTicketTokenGuard], { queryParamsHandling: "preserve" });
	}

	protected signOutPublic(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();
		window.location.reload();
	}

	protected setViewFromTop(): void
	{
		let top = document.getElementById("divMainDashboard");
		if (top !== null)
		{
			top.scrollIntoView();
			top = null;
		}
	}

	protected signOutDownload(stringURL: string): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", stringURL]);
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion


	//#region SETTER

	protected setEditorConfig(): CKEDITOR.config
	{
		const arrayStringFont: Array<string> = ["tahoma"];
		const configEditor: CKEDITOR.config =
    {
    	uiColor: "#ffffff",
    	toolbarGroups:
      [
      	{ name: "basicstyles", groups: [ "basicstyles"]},
      	{ name: "paragraph", groups: ["list"] }
      ],
    	skin: "moono",
    	resize_enabled: false,
    	removePlugins: "elementspath,save,magicline",
    	extraPlugins: "divarea,smiley,justify,indentblock,colordialog",
    	colorButton_foreStyle:
      {
      	element: "font",
      	attributes: { color: "#(color)" },
      },
    	height: 188,
    	removeDialogTabs: "image:advanced;link:advanced",
    	removeButtons: "Subscript,Superscript,Anchor,Source,Table",
    	format_tags: "p;h1;h2;h3;pre;div",
    };

		configEditor.font_names = "";
		configEditor.contentsCss = ["body { font-size : 10px}"];
		configEditor.fontSize_defaultLabel = "tahoma";
		configEditor.fontSize_sizes = "10px";

		for (let numberIndex = 0; numberIndex < arrayStringFont.length; numberIndex++)
		{
			configEditor.font_names = configEditor.font_names + ";" + arrayStringFont[numberIndex];
			arrayStringFont[numberIndex] = "http://fonts.googleapis.com/css?family=" + arrayStringFont[numberIndex].replace(/ /g, "+");
		}

		configEditor.contentsCss = ["/js/ckeditor/contents.css"].concat(
			arrayStringFont
		);

		return configEditor;
	}

	//#endregion
}

//#endregion
