//#region IMPORT

import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "./baseauthourize.component";

//#endregion


//#region CLASS

export class BaseAuthourizeDetailComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanReadOnly: boolean;

	protected _routeActivated: ActivatedRoute;
	protected _location: Location;

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._routeActivated = routeActivated;
		this._location = location;
		this._booleanReadOnly = true;
	}

	//#endregion


	//#region GETTER

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	protected getParameterFromRouterInsert(stringKey: string = StringConstant.STRING_ROUTING_KEY_INSERT): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	protected getParameterFromRouterDetail(stringKey: string = StringConstant.STRING_ROUTING_KEY_DETAIL): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	//#endregion


	//#region CHECK

	protected checkInsert(): boolean
	{
		return this._router.url.indexOf("insert") > -1;
	}

	//#endregion


	//#region NAVIGATION

	public goToBack(): void
	{
		this._location.back();
	}

	//#endregion
}

//#endregion
