//#region CLASS

export class PostalModel
{
	/*
		Attribute - START
		Description : Attribute for postal model, used for link to city.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	Code?: string;
	Name?: string;
	CityID?: number;

	/* Attribute - END */

}

//#endregion