//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class EmailSubscriptionModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for subscription, used for share information from BRINS Website.
		Author: Eka Saputra.
		Created on : Thursday, 14 April 2022. 				Updated on : Thursday, 14 April 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	ID?: number;
	Token?: string;
	Email?: string;
	Newsletter?: boolean;

	/* Attribute - END */

	constructor()
	{
		super();
	}

	//#region FUNCTION


	validateInsert(): ResponseModel
	{
		const regexEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAILWITHOUTEMOJI);

		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Email Subscription");


		if(this.Email == null || this.Email === undefined || this.Email === "")
		{
			modelResponse.MessageContent = "Email tidak boleh kosong";
		}
		else if (this.Email !== undefined && !regexEmail.test(this.Email))
		{
			modelResponse.MessageContent = "Email tidak sesuai format.";
		}
		else if (this.Newsletter === undefined || this.Newsletter === false)
		{
			modelResponse.MessageContent = "Subscription kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion