/* eslint-disable max-len */
//#region IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_DEVICE_TYPE, ENUM_FAQ_TYPE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ContentModel } from "src/app/models/content.model";
import { FrequentlyAskedQuestionsQuestionModel } from "src/app/models/frequentlyaskedquestionsquestion.model";
import { FrequentlyAskedQuestionsQuestionSubtypeModel } from "src/app/models/frequentlyaskedquestionsquestionsubtype.model";
import { FrequentlyAskedQuestionsQuestionTypeModel } from "src/app/models/frequentlyaskedquestionsquestiontype.model";
import { ResponseModel } from "src/app/models/response.model";
import { FrequentlyAskedQuestionsService } from "src/app/services/frequentlyaskedquestions.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-frequentyandquestions",
		templateUrl: "./frequentyandquestions.component.html",
		styleUrls: ["./frequentyandquestions.component.sass"]
	}
)

//#endregion


//#region CLASS

export class FrequentyAndQuestionsComponent extends BaseAuthourizeDetailComponent
{

	//#region DECLARATION

	public _stringConstant = StringConstant;
	public _stringImageDropdown: string;
	public _stringImageDropdownActive: string;

	public _modelTableFAQType: TableModel;
	public _modelFAQQuestionTypeRequest: FrequentlyAskedQuestionsQuestionTypeModel;
	public _modelFAQQuestionTypeResponse: FrequentlyAskedQuestionsQuestionTypeModel;
	public _modelFAQQuestionSubtypeRequest: FrequentlyAskedQuestionsQuestionSubtypeModel;
	public _modelFAQQuestionSubtypeResponse: FrequentlyAskedQuestionsQuestionSubtypeModel;
	public _modelFAQQuestion: FrequentlyAskedQuestionsQuestionModel;
	public _modelFAQQuestionRequest: FrequentlyAskedQuestionsQuestionModel;

	public _numberConstant = NumberConstant;
	public _numberFAQActive: number;
	public _numberFAQProduct: number;
	public _numberFAQQuestion: number;

	public _enumTypeFAQ = ENUM_FAQ_TYPE;
	public _enumTypeFAQActive: number;

	public _arrayModelFAQShow: Array<ContentModel>;
	public _arrayModelFAQQuestionType: Array<FrequentlyAskedQuestionsQuestionTypeModel>;
	public _arrayMdoelFAQQuestion: Array<FrequentlyAskedQuestionsQuestionModel>;

	public _booleanShow: boolean;

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router, public _serviceFAQ: FrequentlyAskedQuestionsService)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelTableFAQType = new TableModel();
		this._modelFAQQuestionTypeRequest = new FrequentlyAskedQuestionsQuestionTypeModel();
		this._modelFAQQuestionTypeResponse = new FrequentlyAskedQuestionsQuestionTypeModel();
		this._modelFAQQuestionSubtypeRequest = new FrequentlyAskedQuestionsQuestionSubtypeModel();
		this._modelFAQQuestionSubtypeResponse = new FrequentlyAskedQuestionsQuestionSubtypeModel();
		this._modelFAQQuestion = new FrequentlyAskedQuestionsQuestionModel();
		this._modelFAQQuestionRequest = new FrequentlyAskedQuestionsQuestionModel();

		this._stringImageDropdown = "../assets/icons/sakina/icon_dropdownaa.svg";
		this._stringImageDropdownActive = "../assets/icons/sakina/icon_dropdown_right.svg";

		this._arrayModelFAQShow = [];
		this._arrayModelFAQQuestionType = [];
		this._arrayMdoelFAQQuestion = [];

		this._booleanShow = false;

		this._enumTypeFAQActive = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;

		this._numberFAQActive = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
		this._numberFAQProduct = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
		this._numberFAQQuestion = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
	}

	//#endregion


	//#region NGONINIT

	ngOnInit(): void
	{
		this.callSelectFrequentlyAskedQuestionsQuestionType(this, () => {});
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region NAVIGATION

	//#endregion


	//#region FUNCTION

	public setNumberFAQActive(numberFAQ: number): void
	{
		if(numberFAQ)
		{
			if(this._numberFAQActive === numberFAQ)
			{
				this._numberFAQActive = this._numberConstant.NUMBER_COMPARE_VALUE_ZERO;
			}
			else
			{
				this._numberFAQActive = numberFAQ;
			}
		}
	}

	//#endregion


	//#region WEBSERVICES

	private callSelectFrequentlyAskedQuestionsQuestionType(componentCurrent: FrequentyAndQuestionsComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		this._modelFAQQuestionTypeRequest = new FrequentlyAskedQuestionsQuestionTypeModel();
		this._modelFAQQuestionTypeRequest.ClientDeviceType = ENUM_DEVICE_TYPE.Website;
		this._modelFAQQuestionTypeRequest.Culture = "id-ID";

		this._serviceFAQ.selectFrequentlyAskedQuestionsQuestionTypeByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelFAQQuestionSubtypeResponse.setModelFromString(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();

						success();
						const arrayModelFAQQuestionType = JSON.parse(modelResponse.Data);
						componentCurrent._arrayModelFAQQuestionType = [];

						let modelFAQQuestionType: FrequentlyAskedQuestionsQuestionTypeModel;

						for (const modelFAQQuestionTypeTemp of arrayModelFAQQuestionType)
						{
							modelFAQQuestionType = new FrequentlyAskedQuestionsQuestionTypeModel();
							modelFAQQuestionType.listModelFrequentlyAskedQuestionsQuestion = undefined;
							modelFAQQuestionType.listModelFrequentlyAskedQuestionsQuestionSubtype = undefined;
							modelFAQQuestionType.setModelFromObject(modelFAQQuestionTypeTemp);
							componentCurrent._arrayModelFAQQuestionType.push(modelFAQQuestionType);
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectFrequentlyAskedQuestionsQuestionType(componentCurrent,() => {}); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelFAQQuestionTypeRequest);
	}

	public callSelectFrequentlyAskedQuestionsQuestionTypeByToken(componentCurrent: FrequentyAndQuestionsComponent, modelFAQQuestionType: FrequentlyAskedQuestionsQuestionTypeModel, numberIndex: number): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		componentCurrent._modelFAQQuestionTypeResponse = new FrequentlyAskedQuestionsQuestionModel();

		if (modelFAQQuestionType.Token !== undefined && modelFAQQuestionType.Token !== null && modelFAQQuestionType.Token !== "")
		{
			this._modelFAQQuestionTypeRequest.Token = modelFAQQuestionType.Token;
			this._numberFAQActive = numberIndex;
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Token";
			modelResponse.MessageContent = "Terjadi kesalahan pada token, silahkan hubungi pengembang!";
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});
		}


		this._serviceFAQ.selectFrequentlyAskedQuestionsQuestionTypeByTokenForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelFAQQuestionTypeResponse.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectFrequentlyAskedQuestionsQuestionTypeByToken(componentCurrent, modelFAQQuestionType, numberIndex); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelFAQQuestionTypeRequest);
	}

	public callSelectFrequentlyAskedQuestionsQuestionSubtypeByToken(componentCurrent: FrequentyAndQuestionsComponent, modelFAQQuestionSubtype: FrequentlyAskedQuestionsQuestionSubtypeModel, numberIndex: number): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		componentCurrent._modelFAQQuestionSubtypeResponse = new FrequentlyAskedQuestionsQuestionSubtypeModel();

		if (modelFAQQuestionSubtype.Token !== undefined && modelFAQQuestionSubtype.Token !== null && modelFAQQuestionSubtype.Token !== "")
		{
			this._modelFAQQuestionSubtypeRequest.Token = modelFAQQuestionSubtype.Token;
			this._numberFAQProduct = numberIndex;
		}
		else
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Token";
			modelResponse.MessageContent = "Terjadi kesalahan pada token, silahkan hubungi pengembang!";
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});
		}

		this._modelFAQQuestionSubtypeRequest.modelFrequentlyAskedQuestionsQuestionType = undefined;
		this._modelFAQQuestionSubtypeRequest.listModelFrequentlyAskedQuestionsQuestion = undefined;

		this._serviceFAQ.selectFrequentlyAskedQuestionsQuestionSubtypeByTokenForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelFAQQuestionSubtypeResponse.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectFrequentlyAskedQuestionsQuestionSubtypeByToken(componentCurrent, modelFAQQuestionSubtype, numberIndex); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelFAQQuestionSubtypeRequest);
	}

	//#endregion

}

//#endregion
