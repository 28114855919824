//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { BRINSUpdateModel } from "../models/brinsupdate.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class BRINSUpdateService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectBRINSUpdateByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRINSUPDATE_SELECTBRINSUPDATEBYATTRIBUTES);
	}

	selectBRINSUpdateByToken(interfaceGeneralService: GeneralServiceInterface, modelBRINSUpdate: BRINSUpdateModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBRINSUpdate), WebAddressConstant.STRING_URL_BRINSUPDATE_SELECTBRINSUPDATEBYTOKEN);
	}

	selectBRINSUpdateByAttributesForCustomer(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRINSUPDATE_SELECTBRINSUPDATEBYATTRIBUTESFORCUSTOMER);
	}

	//#endregion
}

//#endregion