//#region IMPORT

import { AfterViewInit, Component } from "@angular/core";
import { Event, NavigationEnd, NavigationStart, Router, RouterEvent } from "@angular/router";
import { filter } from "rxjs";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-root",
		templateUrl: "./app.component.html",
		styleUrls: ["./app.component.sass"]
	}
)

//#endregion


//#region CLASS

export class AppComponent implements AfterViewInit
{
	title = "BRINSwebsite";

	//#region DECLARATION

	private _elementHTMLImgLoadingCircle: HTMLElement | null;
	private _elementHTMLDivCurtainLoading: HTMLElement | null;
	private _elementHTMLSpanLoadingPercentage: HTMLElement | null;
	private _booleanAfterViewChecked: boolean = false;

	//#endregion


	//#region CONSTRUCTOR

	constructor(router: Router)
	{
		this._elementHTMLImgLoadingCircle = document.getElementById("imageLoadingCircle");
		this._elementHTMLDivCurtainLoading = document.getElementById("divCurtainLoading");
		this._elementHTMLSpanLoadingPercentage = document.getElementById("spanLoadingPercentage");

		router.events.pipe
		(
			filter((event: Event): event is RouterEvent => event instanceof RouterEvent)
		).subscribe
		(
			(eventRouter: RouterEvent): void =>
			{
				if (this._elementHTMLDivCurtainLoading != null && this._elementHTMLImgLoadingCircle != null && this._elementHTMLSpanLoadingPercentage != null)
				{
					if (eventRouter instanceof NavigationStart)
					{
						this._elementHTMLDivCurtainLoading.style.display = "block";
						this._elementHTMLImgLoadingCircle.style.display = "block";
						this._elementHTMLSpanLoadingPercentage.style.display = "none";
					}
					else if (eventRouter instanceof NavigationEnd)
					{
						this._elementHTMLDivCurtainLoading.style.display = "none";
						this._elementHTMLSpanLoadingPercentage.style.display = "block";
						this._elementHTMLImgLoadingCircle.style.display = "none";
					}
					else
					{

					}
				}
				else
				{

				}
			}
		);
	}

	//#endregion


	ngAfterViewInit(): void
	{
		const elementHTMLButtonNotificationClose = document.getElementById("buttonNotificationClose");

		if (elementHTMLButtonNotificationClose == null || elementHTMLButtonNotificationClose === undefined)
		{

		}
		else
		{
			elementHTMLButtonNotificationClose.onclick = function()
			{
				const elementHTMLBotikaLayout = document.getElementById("BotikaWidget");

				if (elementHTMLBotikaLayout == null || elementHTMLBotikaLayout === undefined)
				{

				}
				else
				{
					const elementHTMLDivNotificationInstall = document.getElementById("divNotificationInstall");

					if (elementHTMLDivNotificationInstall == null || elementHTMLDivNotificationInstall === undefined)
					{

					}
					else
					{
						if (elementHTMLBotikaLayout == null || elementHTMLBotikaLayout === undefined)
						{

						}
						else
						{
							elementHTMLDivNotificationInstall.style.display = "none";
							elementHTMLBotikaLayout.style.bottom = "20px";
						}
					}
				}
			};
		}
	}
}

//#endregion